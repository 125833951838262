import { Typography } from '@mui/material'
import React from 'react'

const Desc = ({text,fontsize}) => {
  return (
    <Typography
    sx={{
      fontSize: fontsize?fontsize:"1.15rem",
      fontWeight: "500",
      color:"#000",
      fontFamily:"KanitRegular !important"
    }}
  >
   {text}
  </Typography>
  )
}

export default Desc