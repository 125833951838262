import React from "react";
import { Box, Container, Typography } from "@mui/material";
import XIcon from '@mui/icons-material/X';
// import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
// import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from "@mui/icons-material/Facebook";
// import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
// import EmailIcon from '@mui/icons-material/Email';


const Footer = () => {
  return (
    <Box
      sx={{
        py:"rem",
        lineHeight:"0",
        color: "#fff",
        "& h1,h6,p,a": {
          fontFamily: "inherit",
        },
        "& a": {
          color:"#fff",
          transition: "all 0.3s ease",
          "&:hover":{
            color:"rgb(58, 42, 209)"
          }
        },
        background: "#111",
      }}
    >
      <Container>
        {/* <Box sx={{ borderBottom: "1px solid #fff" }}>
          <Grid container spacing={2} py="2rem">
            <Grid item md={4} sm={12} xs={12}>
              <Box
                sx={{
                  display: "grid",
                  gap: "1rem",
                  textAlign: { md: "start", xs: "center" },
                }}
              >
                <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                  About us
                </Typography>
                <Typography sx={{ fontSize: "17px", fontWeight: "normal" }}>
                Crescent Intellects is a legal services firm based in New Delhi, DL. With a team of highly skilled legal professionals, we are dedicated to providing exceptional legal solutions to our clients.
                </Typography>
             
              </Box>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
            <Box sx={{  display: "grid",
                  gap: "1rem",
                  textAlign: { md: "center", xs: "center" },}}>
                <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                  Contact us
                </Typography>
                <Typography sx={{display:"flex",gap:"0.5rem",alignItems:"center",py:"0.5rem",justifyContent: { md: "center", xs: "center" },}}>
                <PhoneCallbackIcon/>{" "} <Typography  component={"span"}>+91-1234567890</Typography>
                </Typography>
                <Typography sx={{display:"flex",gap:"0.5rem",alignItems:"center",justifyContent: { md: "center", xs: "center" }}}>
                <EmailIcon/>{" "} <Typography sx={{textDecoration:"underline",}} component={"span"}><a href="mailto:info@crescentintellects.com">info@crescentintellects.com</a></Typography>
                </Typography>
                </Box>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box
                sx={{
                  textAlign: { md: "end", xs: "center" },
                }}
              >
                <Typography sx={{ fontSize: "22px", fontWeight: "600",mb:"1rem" }}>
               Location
                </Typography>
              
                <iframe title="Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.8378007271353!2d77.5149989!3d28.484430999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cea621934e75b%3A0x3a82c0c84cfa951e!2si%2C%2011%2C%20Beta%202%20Rd%2C%20Block%20F%2C%20Beta%20II%2C%20Greater%20Noida%2C%20Rampur%20Jagir%2C%20Uttar%20Pradesh%20201310!5e0!3m2!1sen!2sin!4v1709575354695!5m2!1sen!2sin" style={{border:0,width:"100%",height:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </Box>
            </Grid>
          </Grid>
        </Box> */}
        <Box
          sx={{
            p: "1.5rem 0rem",
            display: "flex",
            justifyContent: { sm: "space-between", xs: "center" },
            gap: "1rem",
            flexDirection: { sm: "row", xs: "column" },
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "15px", fontWeight: "normal",textAlign:{sm:"start",xs:"center"} }}>
            © 2023-2024 Crescent Intellects. All Rights Reserved.
          </Typography>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <Box className="social_icon" component={"a"} rel="noreferrer" href="https://twitter.com/CIntellects" target="_blank">
              <XIcon sx={{ color: "#fff" }} />
            </Box>
            <Box className="social_icon" component={"a"} rel="noreferrer" href="https://www.linkedin.com/company/crescent-intellects2023/" target="_blank">
              <LinkedInIcon sx={{ color: "#fff" }} />
            </Box>
            <Box className="social_icon" component={"a"} rel="noreferrer" href="https://www.instagram.com/crescentintellects/" target="_blank">
              <InstagramIcon sx={{ color: "#fff" }} />
            </Box>
            {/* <Box className="social_icon" >
              <TelegramIcon sx={{ color: "#fff" }} />
            </Box>
            <Box className="social_icon">
              <YouTubeIcon sx={{ color: "#fff" }} />
            </Box> */}
            <Box className="social_icon" component={"a"} rel="noreferrer" href="https://www.facebook.com/profile.php?id=61556653526468" target="_blank">
              <FacebookIcon sx={{ color: "#fff" }} />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
