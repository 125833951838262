import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import PatentSectionBar from "./PatentSectionBar";
import PatentSearching from "../../Assets/Images/PatentSearching.png"



const PatentSearch = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <Box>
        </Box>
       <Grid container spacing={4} mb={"3rem"}>
       <Grid item md={8.5} sm={12} xs={12}>
        <Typography sx={{color:"#002856",fontSize:"1.1rem",fontWeight:"600",pb: "0.2rem",}}>Patent</Typography>
   <Box sx={{
   "& p":{
    fontSize:"1.1rem",
      fontWeight: "500",
      pb:"1.5rem",
      textAlign:{lg:"justify",xs:"inherit"},
      color:"#000"
   }
    }}>
   <Typography sx={{fontWeight:"500 !important",fontSize:"1.1rem !important",color:"#000 !important"}}>
   Crescent Intellects offers a variety of patent searches to assist clients in understanding the patent landscape, assessing the patentability of their inventions, and making informed decisions regarding intellectual property strategies. Here are the types of patent searches provided by Crescent Intellects:
  </Typography>
  <Typography component={"img"} width={"100%"} src={PatentSearching} pb={"1.5rem"}/>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Novelty/Patentability Search:</b>
   <br/>
   This search is conducted to determine the novelty and patentability of a client's invention. It aims to identify prior art (existing patents, publications, or other disclosures) relevant to the invention, helping clients understand whether their idea is likely to meet the criteria for patent protection.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Prior Art Search:</b>
   <br/>
   Prior art searches are more general searches aimed at uncovering existing knowledge and technology related to a particular field. These searches provide a broader perspective on the state of the art and can be useful for strategic planning and research purposes.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>State-of-the-Art Search:</b>
   <br/>
   State-of-the-art searches involve a comprehensive exploration of existing technologies and advancements within a specific technical field. These searches are not limited to a particular invention and are useful for staying informed about the latest developments in a given industry.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Freedom-to-Operate (FTO) Search:</b>
   <br/>
   FTO searches, also known as clearance or right-to-use searches, help clients assess whether their proposed product or technology may infringe on existing patents. These searches are crucial for avoiding potential legal issues and understanding the competitive landscape before launching a new product or entering a market.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Infringement Search:</b>
   <br/>
   Infringement searches are conducted to assess whether a specific product or process may infringe on existing patents. These searches are typically more focused than FTO searches and are designed to provide insights into potential legal risks associated with a client's current or planned activities.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Validity Search:</b>
   <br/>
   Validity searches are performed to assess the strength and validity of an existing patent. These searches aim to uncover prior art that may challenge the validity of a patent, providing valuable information for patent litigation, post-grant proceedings, or licensing negotiations.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>State-specific or Jurisdictional Search:</b>
   <br/>
   State-specific searches focus on the patent landscape within a particular jurisdiction. These searches are beneficial for clients interested in understanding the patent environment in a specific country or region, considering factors such as local regulations and market conditions.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>International (PCT) Search:</b>
   <br/>
   International searches are conducted in accordance with the Patent Cooperation Treaty (PCT). These searches are part of the PCT application process and provide a comprehensive analysis of the prior art relevant to an invention on an international scale, helping applicants make informed decisions about pursuing patent protection in multiple countries.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Competitor Patent Monitoring:</b>
   <br/>
   Crescent Intellects offers ongoing monitoring services to keep clients informed about new patents filed by competitors. This proactive approach allows clients to stay ahead of industry trends, identify potential areas for collaboration or licensing, and strategically position themselves within their market.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Customized Search Services:</b>
   <br/>
   Crescent Intellects provides customized search services based on the specific needs and goals of clients. These may include searches tailored to a unique technology, industry, or strategic objective, ensuring that the search process aligns closely with the client's requirements.
   
  </Typography>
   <Typography>
   At Crescent Intellects, our diverse range of patent searches is designed to cater to the varied needs of clients, providing them with valuable insights to make well-informed decisions in the complex landscape of intellectual property.
   
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Landscape Analysis/Search:</b>
   <br/>
   Landscape analysis involves mapping out the entire patent landscape within a given technological domain. This comprehensive search helps clients understand the competitive landscape, emerging trends, and potential opportunities for innovation.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>White Space Analysis/Search:</b>
   <br/>
   White space analysis involves identifying areas within a technological field where there is little or no existing patent activity. This type of search can help clients uncover untapped opportunities for innovation and strategic development.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Technology Transfer Search:</b>
   <br/>
   Technology transfer searches focus on identifying technologies available for licensing or collaboration. These searches assist clients in discovering opportunities to leverage external technologies to enhance their own product offerings or expand their market presence.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Patentability Opinions:</b>
   <br/>
   We provide patentability opinions based on the results of our comprehensive searches. These opinions offer clients insights into the likelihood of obtaining a patent and guide them in making informed decisions about pursuing patent protection for their inventions.
  </Typography>


   </Box>
   </Grid>
        <Grid item md={3.5} sm={12} xs={12}>
        <PatentSectionBar/>
        </Grid>
       </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default PatentSearch;
