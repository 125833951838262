import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import CareerSectionBar from "./CareerSectionBar";
import carrer from "../../Assets/Images/carrer.png"



const Career = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
      
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Career"} />
        </Box>
        <Box>
        </Box>
       <Grid container spacing={4} mb={"3rem"}>
        <Grid item md={8.5} sm={12} xs={12}>
        <Typography sx={{color:"#002856",fontSize:"1.1rem",fontWeight:"600",pb: "0.2rem",}}>Career Opportunities at Crescent Intellects</Typography>
  
        <Box
          sx={{
            "& p": {
              fontSize: "1.1rem",
              fontWeight: "500",
              pb: "1.5rem",
              textAlign: { lg: "justify", xs: "inherit" },
              color:"#000"
              
            },
          }}
        >
          <Typography>
          Joining Crescent Intellects means becoming part of a dynamic team committed to delivering excellence in intellectual property services. As a growing firm at the forefront of innovation, we offer diverse career opportunities for talented professionals seeking to make an impact in the field of intellectual property.
          </Typography>
          <Typography component={"img"} width={"100%"} src={carrer} pb={"1rem"}/>
    
        </Box>
        </Grid>
        <Grid item md={3.5} sm={12} xs={12}>
        <CareerSectionBar/>
        </Grid>
       </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Career;
