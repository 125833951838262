import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
// import Title from "../../components/Title";
// import Desc from "../../components/Desc";
// import PlaceIcon from "@mui/icons-material/Place";
import Form from "../../components/Form_1";
import PinDropIcon from "@mui/icons-material/PinDrop";
import EmailIcon from "@mui/icons-material/Email";
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';

const contactUsData = [
  {
    image:
      "https://img.traveltriangle.com/blog/wp-content/uploads/2021/11/Places-to-Visit-in-Uttar-Pradesh.jpg",
    title: "Uttar Pradesh",
    desc: "I-11, Beta-2 Greater Noida, Uttar Pradesh 201306",
    link: "https://www.google.com/maps/place/i,+11,+Beta+2+Rd,+Block+F,+Beta+II,+Greater+Noida,+Rampur+Jagir,+Uttar+Pradesh+201310/@28.484431,77.512424,17z/data=!3m1!4b1!4m5!3m4!1s0x390cea621934e75b:0x3a82c0c84cfa951e!8m2!3d28.484431!4d77.5149989?entry=ttu",
  },
  {
    image: "https://www.holidify.com/images/bgImages/DELHI.jpg",
    title: "New Delhi",
    desc: "268 C/2, Malviya Nagar, New Delhi, 110017,",
    link: "https://www.google.com/maps/place/28%C2%B031'42.3%22N+77%C2%B012'28.7%22E/@28.5284177,77.2053939,17z/data=!3m1!4b1!4m4!3m3!8m2!3d28.5284177!4d77.2079688?hl=en&entry=ttu",
  },
  {
    image:
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/25/37/b6/73/caption.jpg?w=800&h=-1&s=1",
    title: "United Kingdom",
    desc: "Aspire Point, Stratford High Street, Stratford, London, United Kingdom, E152ZL",
    link: "https://www.google.com/maps/place/Aspire+Point/@51.5367221,-0.0042691,17z/data=!3m1!4b1!4m6!3m5!1s0x48761d5a75fd9067:0xc2d91ab50921ff04!8m2!3d51.5367221!4d-0.0042691!16s%2Fg%2F11g0h_51h_?entry=ttu",
  },
  // {
  //   image:
  //     "https://static.javatpoint.com/tourist-places/images/tourist-places-in-kashmir1.png",
  //   title: "Jammu and Kashmir",
  //   desc: "Badroo, Kulgam, Jammu and Kashmir, 192232",
  //   link: "https://www.google.com/maps/place/FAROOQ+AHMAD+BHAT/@33.7369409,75.0303459,17z/data=!3m1!4b1!4m6!3m5!1s0x38e2034818190975:0xf72f2a9b04eaceeb!8m2!3d33.7369409!4d75.0303459!16s%2Fg%2F11q25446k9?entry=ttu",
  // },
];

const Contact = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []); 
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        {/* <Box py={"1rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Contact Us"} />
        </Box> */}
        <Box sx={{py:{md:"3.8rem",xs:"0rem"}}}>
          <Box>
            <Grid container spacing={2} py={"2rem"}>
              <Grid item md={4} sm={12} xs={12}>
                <Box
                  sx={{
                    backgroundColor: "rgb(34, 75, 141)",
                    p: { sm: "2rem", xs: "1rem" },
                  }}
                >
                  {/* <Typography
                    component={"div"}
                    fontWeight={"600"}
                    sx={{
                      fontSize: "1.6rem",
                      // pb: ".5rem",
                      color: "#fff",
                      textAlign: { lg: "justify", xs: "inherit" },
                      fontFamily: "Kanit!important",
                    }}
                  >
                    Contact Details
                  </Typography> */}
                  {/* <Typography
                    component={"div"}
                    sx={{
                      fontSize: "1rem",
                      pt: "0.5rem",
                      lineHeight: "22px",
                      color: "#fff",
                      textAlign: { lg: "justify", xs: "inherit" },
                      fontFamily: "KanitRegular!important",
                    }}
                  >
                    Get in touch with us for any questions about our industries
                    or projects.
                  </Typography> */}
                  <Box py={"1.5rem"}>
                    {contactUsData.map((e, index) => (
                      <Box
                        sx={{
                          pb: "1rem",
                          display: "flex",
                          gap: "0.2rem",
                        }}
                      >
                        <PinDropIcon
                          sx={{ color: "#fff", fontSize: "1.8rem" }}
                        />
                        <Box>
                          <Typography
                            component={"div"}
                            sx={{
                              fontSize: "15px",
                              lineHeight: "22px",
                              color: "#fff",
                              // textAlign: { lg: "justify", xs: "inherit" },
                              fontFamily: "KanitRegular!important",
                            }}
                          >
                            <b style={{ fontSize: "15px !important" }}>
                              {" "}
                              {e.title}
                            </b>
                            <br />
                            {e.desc}
                          </Typography>
                          <Box textAlign={"start"}>
                            <a
                              style={{
                                color: "#fff",
                                textDecoration: "none",
                                fontSize: "14px",
                                fontWeight:"600"
                              }}
                              href={e.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Find us on google map
                            </a>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                    <Box
                      sx={{
                        pb: "1rem",
                        display: "flex",
                        gap: "0.3rem",
                      }}
                    >
                      <EmailIcon sx={{ color: "#fff", fontSize: "1.5rem" }} />
                      <Box>
                        <Typography
                          component={"div"}
                          sx={{
                            fontSize: "15px",
                            lineHeight: "22px",
                            color: "#fff",
                            // textAlign: { lg: "justify", xs: "inherit" },
                            fontFamily: "KanitRegular!important",
                          }}
                        >
                          <b style={{ fontSize: "15px !important" }}>
                            {" "}
                            Email
                          </b>
                          <br />
                          <Typography
                            component={"a"}
                            sx={{ color: "#fff", textDecoration: "none" }}
                            href="mailto:example@example.com"
                          >
                            info@crescentintellects.com
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        // pb: "1rem",
                        display: "flex",
                        gap: "0.3rem",
                      }}
                    >
                      <PhoneCallbackIcon sx={{ color: "#fff", fontSize: "1.2rem" }} />
                      <Box>
                        <Typography
                          component={"div"}
                          sx={{
                            fontSize: "15px",
                            lineHeight: "24px",
                            color: "#fff",
                            // textAlign: { lg: "justify", xs: "inherit" },
                            fontFamily: "KanitRegular!important",
                          }}
                        >
                          <b style={{ fontSize: "15px !important" }}>
                            {" "}
                            Contacts
                          </b>
                          
                          <br/>
                          +911203687340
                          <br/>
                          +447442561090
                          <br />
                        +919891110395
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={8} sm={12} xs={12}>
                <Form />
              </Grid>
            </Grid>
            {/* <Grid container spacing={4} pb={"2rem"}>
              {contactUsData.map((e, index) => (
                <Grid item md={4} sm={6} xs={12} key={index}>
                  <Box textAlign={"center"}>
                    <Typography
                      component={"img"}
                      src={e.image}
                      sx={{ width: "100%", height: "250px" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        pt: "0.2rem",
                        // pb: "0rem !important",
                        color: "#002856",
                      }}
                    >
                      {e.title}
                    </Typography>
                    <Desc text={e.desc} fontsize={"1rem !important"} />
                    <Box textAlign={"start"}>
                      <a href={e.link} target="_blank" rel="noreferrer">
                        <PlaceIcon
                          titleAccess="Location"
                          sx={{
                            background: "rgb(34, 75, 141)",
                            color: "#fff",
                            p: "3px",
                            borderRadius: "5px",
                            verticalAlign: "middle",
                          }}
                        />
                      </a>{" "}
                      <Typography
                        component={"span"}
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          pt: "0.2rem",
                          pb: "0rem !important",
                          color: "#002856",
                        }}
                      >
                        Find us on google map
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid> */}
            {/* <Typography
              sx={{
                fontWeight: "600",
                fontSize: {
                  sm: "2rem !important",
                  xs: "1.3rem !important",
                  textAlign: "center !important",
                },
              }}
            >
              Drop us a line for your query : (CONTACT FORM)
            </Typography> */}
          </Box>
          {/* <Box pt={"2rem"}>
            <Form display="none" />
          </Box> */}
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Contact;
