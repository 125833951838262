import React, { useEffect } from "react";
import ScrollButton from "../components/ScrollButton";
import Navbar from "../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../components/Footer";
import Title from "../components/Title";
import ServiceSectionBar from "./Services/ServiceSectionBar";
import licensing from "../Assets/Images/licensing.jpeg";

const Licensing = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <Grid container spacing={4} mb={"3rem"}>
          <Grid item md={8.5} sm={12} xs={12}>
            <Typography
              sx={{
                color: "#002856",
                fontSize: "1.1rem",
                fontWeight: "600",
                pb: "0.2rem",
              }}
            >
              Licensing and Technology Transfer Services
            </Typography>
            <Box
              sx={{
                "& p": {
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  pb: "1.5rem",
                  textAlign: { lg: "justify", xs: "inherit" },
                  color: "#000",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  fontSize: "1.1rem !important",
                  color: "#000 !important",
                }}
              >
                At Crescent Intellects, we specialize in facilitating seamless
                licensing agreements and technology transfers to help you unlock
                the full potential of your intellectual property (IP) assets.
                Our comprehensive Licensing and Technology Transfer services are
                designed to maximize the value of your IP portfolio, foster
                innovation, and drive business growth. With our expertise in IP
                law, negotiation, and strategic planning, we provide tailored
                solutions to meet your specific licensing and technology
                transfer needs.
              </Typography>
              <Typography
                component={"img"}
                width={"100%"}
                src={licensing}
                pb={"1rem"}
              />
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  IP Portfolio Analysis and Strategy Development:
                </b>
                <br />
                We begin by conducting a comprehensive analysis of your IP
                portfolio to identify licensing and technology transfer
                opportunities. Our team evaluates the scope, market potential,
                and strategic alignment of your patents, trademarks, copyrights,
                and trade secrets. Based on our analysis, we develop a
                customized licensing and technology transfer strategy tailored
                to your business goals and objectives.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Identification of Licensing Opportunities:
                </b>
                <br />
                We assist you in identifying potential licensing opportunities
                for your IP assets, including patents, trademarks, copyrights,
                and trade secrets. Whether you are looking to license your
                technology to third parties, collaborate with partners, or enter
                new markets, we help you identify potential licensees and
                strategic partners that can leverage your IP assets to drive
                innovation and create value.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Licensing Agreement Negotiation and Drafting:
                </b>
                <br />
                Our experienced licensing professionals guide you through the
                negotiation and drafting of licensing agreements tailored to
                your specific requirements. We help you negotiate favourable
                terms and conditions, including licensing fees, royalties,
                exclusivity clauses, and territorial restrictions. Our team
                ensures that your licensing agreements are clear, comprehensive,
                and legally enforceable, protecting your rights and interests
                while minimizing risks and liabilities.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Technology Transfer Assistance:
                </b>
                <br />
                We provide comprehensive assistance throughout the technology
                transfer process, from technology assessment and valuation to
                negotiation and implementation of technology transfer
                agreements. Whether you are transferring technology internally
                within your organization or licensing it to third parties, we
                ensure a smooth and efficient transfer process that maximizes
                the value of your technology assets and facilitates successful
                commercialization.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Due Diligence and Risk Assessment:
                </b>
                <br />
                Conducting thorough due diligence is essential to assess the
                value, validity, and enforceability of IP assets involved in
                licensing and technology transfer transactions. Our team
                performs comprehensive due diligence to identify potential risks
                and liabilities associated with the IP assets, including
                infringement risks, ownership disputes, and contractual
                obligations. We provide you with valuable insights and
                recommendations to mitigate risks and ensure a successful
                licensing or technology transfer transaction.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Licensing Compliance and Monitoring:
                </b>
                <br />
                Once licensing agreements are in place, we assist you in
                monitoring compliance with contractual obligations, including
                payment of royalties, maintenance of quality standards, and
                enforcement of intellectual property rights. Our team ensures
                that all parties adhere to the terms and conditions of the
                licensing agreements, protecting your interests and maximizing
                the value of your licensing arrangements.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <ServiceSectionBar />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Licensing;
