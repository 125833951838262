import React from 'react'
import { NavLink } from "react-router-dom";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { Box, Typography } from '@mui/material';


const PatentSectionBar = () => {
    const navLinkStyles = ({ isActive }) => {
        return {
          color:isActive?"#000":"#4d4d4d",
          lineHeight:"1.1rem !important",
          fontSize:"14px !important",
          transition:"0.3s all !important",
          textTransform:"capitalize !important",
          textDecoration:"none",
          fontFamily:"KanitRegular"
          // fontWeight:isActive?"700":"600"
        };
      };
  return (
    <Box sx={{display:"grid",gap:"0.7rem",justifyContent:"flex-start",background:"#e0e0e0",p:"1.5rem","& div":{
        display:"flex",gap:"0.5rem",alignItems:"center",
        "& svg":{
            transform:"rotate(270deg)",fontSize:"2rem",color:"#002856",verticalAlign:"bottom",
        }
      }}}>
      <Typography sx={{color:"#002856",fontSize:"1.3rem",fontWeight:"600",pb:"0.5rem"}}>Patent</Typography>
      <Box><ArrowCircleDownIcon /><NavLink style={navLinkStyles} to="/patent" disableRipple >Patent</NavLink></Box>
      <Box pb={"1rem"}><ArrowCircleDownIcon /> <NavLink style={navLinkStyles} to="/patent/prosecution" disableRipple >Patent Prosecution</NavLink></Box>

        </Box>
  )
}

export default PatentSectionBar