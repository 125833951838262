import React from "react";
import { NavLink } from "react-router-dom";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { Box, Typography } from "@mui/material";

const ServiceSectionBar = () => {

  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "#000" : "#4d4d4d",
      lineHeight: "1.2rem !important",
      fontSize: "14px !important",
      transition: "0.3s all !important",
      textTransform: "capitalize !important",
      textDecoration: "none",
      fontFamily:"KanitRegular"
      // fontWeight: isActive ? "700" : "600",
    };
  };
  return (
    <Box
      sx={{
        display: "grid",
        gap: "0.7rem",
        justifyContent: "flex-start",
        background: "#e0e0e0",
        p: "1.5rem",
        "& div": {
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          "& svg": {
            transform: "rotate(270deg)",
            fontSize: "2rem",
            color: "#002856",
            verticalAlign: "bottom",
          },
        },
      }}
    >
      <Typography
        sx={{
          color: "#002856",
          fontSize: "1.3rem",
          fontWeight: "600",
          pb: "0.5rem",
        }}
      >
        Services
      </Typography>
      <Box>
        <ArrowCircleDownIcon />
        <NavLink
          style={navLinkStyles}
          to="/patent"
          disableRipple
          
        >
          Patent
        </NavLink>
      </Box>
      <Box>
        <ArrowCircleDownIcon />
        <NavLink
          style={navLinkStyles}
          to="/trademark"
          disableRipple
          
        >
          Trademark
        </NavLink>
      </Box>
      <Box>
        <ArrowCircleDownIcon />
        <NavLink
          style={navLinkStyles}
          to="/copyright"
          disableRipple
          
        >
          Copyright
        </NavLink>
      </Box>
      <Box>
        <ArrowCircleDownIcon />
        <NavLink
          style={navLinkStyles}
          to="/design"
          disableRipple
          
        >
          Design
        </NavLink>
      </Box>
      <Box>
        <ArrowCircleDownIcon />
        <NavLink
          style={navLinkStyles}
          to="/trade-secret"
          disableRipple
          
        >
          Trade secret protection
        </NavLink>
      </Box>
      <Box>
        <ArrowCircleDownIcon />
        <NavLink
          style={navLinkStyles}
          to="/portfolio"
          disableRipple
          
        >
         IP Portfolio management
        </NavLink>
      </Box>
    
      <Box>
        <ArrowCircleDownIcon />
        <NavLink
          style={navLinkStyles}
          to="/diligence"
          disableRipple
          
        >
         IP Due deligence
        </NavLink>
      </Box>
      <Box>
        <ArrowCircleDownIcon />
        <NavLink
          style={navLinkStyles}
          to="/licensing"
          disableRipple
          
        >
        Licensing and Technology transfer
        </NavLink>
      </Box>
      <Box>
        <ArrowCircleDownIcon />
        <NavLink
          style={navLinkStyles}
          to="/litigation"
          disableRipple
          
        >
        IP Litigation and Enforcement
        </NavLink>
      </Box>
      <Box>
        <ArrowCircleDownIcon />
        <NavLink
          style={navLinkStyles}
          to="/domain"
          disableRipple
          
        >
        Domain name disputes
        </NavLink>
      </Box>
      <Box pb={"1rem"}>
        <ArrowCircleDownIcon />
        <NavLink
          style={navLinkStyles}
          to="/training"
          disableRipple
          
        >
        Customized IP Training and Workshops
        </NavLink>
      </Box>
   
    </Box>
  );
};

export default ServiceSectionBar;
