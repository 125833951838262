import React, { useEffect } from "react";
import ScrollButton from "../components/ScrollButton";
import Navbar from "../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../components/Footer";
import Title from "../components/Title";
import ServiceSectionBar from "./Services/ServiceSectionBar";
import domain from "../Assets/Images/domain.jpeg";

const Domain = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <Grid container spacing={4} mb={"3rem"}>
          <Grid item md={8.5} sm={12} xs={12}>
            <Typography
              sx={{
                color: "#002856",
                fontSize: "1.1rem",
                fontWeight: "600",
                pb: "0.2rem",
              }}
            >
              Domain Name Disputes Resolution Services
            </Typography>
            <Box
              sx={{
                "& p": {
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  pb: "1.5rem",
                  textAlign: { lg: "justify", xs: "inherit" },
                  color: "#000",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  fontSize: "1.1rem !important",
                  color: "#000 !important",
                }}
              >
                Crescent Intellects offers comprehensive Domain Name Disputes
                Resolution services to assist individuals and businesses in
                resolving conflicts related to domain names effectively. With
                our deep expertise in domain name law, dispute resolution
                mechanisms, and strategic negotiation, we provide tailored
                solutions to address your specific domain name disputes and
                protect your online identity.
              </Typography>
              <Typography
                component={"img"}
                width={"100%"}
                src={domain}
                pb={"1rem"}
              />
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Case Evaluation and Strategy Development:
                </b>
                <br />
                We begin by conducting a thorough evaluation of your domain name
                dispute, including analyzing the relevant facts, reviewing
                applicable laws and regulations, and assessing the strength of
                your legal position. Based on our assessment, we develop a
                strategic resolution strategy tailored to your goals and
                objectives, whether it is recovering a domain name, defending
                against a claim, or negotiating a settlement.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Domain Name Dispute Resolution Mechanisms:
                </b>
                <br />
                We assist you in navigating the various dispute resolution
                mechanisms available for resolving domain name disputes,
                including the Uniform Domain-Name Dispute-Resolution Policy
                (UDRP), the Uniform Rapid Suspension (URS) system, and
                traditional litigation. Our experienced attorneys provide
                guidance on the most appropriate dispute resolution mechanism
                based on the specific circumstances of your case and work
                diligently to achieve a favourable outcome.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  UDRP and URS Representation:
                </b>
                <br />
                If your domain name dispute falls within the scope of the UDRP
                or URS, we provide skilled representation throughout the dispute
                resolution process. Our team handles all aspects of the
                proceedings, from preparing and filing the complaint to
                responding to counterclaims and representing you in arbitration
                hearings. We work tirelessly to protect your rights and
                interests and secure a favourable resolution to your domain name
                dispute.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Litigation Support:
                </b>
                <br />
                In cases where traditional litigation is necessary to resolve a
                domain name dispute, our experienced litigators provide skilled
                representation in state and federal courts. We handle all
                aspects of domain name litigation, including pleadings,
                discovery, motion practice, trial, and appeal. Our team develops
                a robust litigation strategy tailored to your specific
                objectives and works diligently to achieve a successful outcome
                in court.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Negotiation and Settlement:
                </b>
                <br />
                We explore all available options for resolving domain name
                disputes efficiently and amicably, including negotiation and
                settlement. Our experienced negotiators work tirelessly to reach
                a favourable settlement that protects your interests while
                minimizing costs and disruption to your business operations. We
                prioritize your needs and objectives, providing personalized
                attention and strategic guidance throughout the negotiation
                process.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <ServiceSectionBar />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Domain;
