import React from 'react'

import { NavLink } from "react-router-dom";
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { Box, Typography } from '@mui/material';


const AboutSectionBar = () => {
    const navLinkStyles = ({ isActive }) => {
        return {
          color:isActive?"#000":"#4d4d4d",
          fontWeight:isActive?"500":"500",
          lineHeight:"1rem !important",
          fontSize:"14px !important",
          transition:"0.3s all !important",
          textTransform:"capitalize !important",
          textDecoration:"none",
          fontFamily:"KanitRegular"
        };
      };
  return (
    <Box sx={{display:"grid",gap:"0.7rem",justifyContent:"flex-start",background:"#e0e0e0",p:"1.5rem","& div":{
        display:"flex",gap:"0.5rem",alignItems:"center",
        "& svg":{
            transform:"rotate(270deg)",fontSize:"2rem",color:"#002856",verticalAlign:"bottom",
        }
      }}}>
      <Typography sx={{color:"#002856",fontSize:"1.3rem",fontWeight:"600",pb:"0.5rem"}}>About Us</Typography>
      <Box><ArrowCircleDownIcon /><NavLink style={navLinkStyles} to="/about" disableRipple >Who we are</NavLink></Box>
      <Box><ArrowCircleDownIcon /><NavLink style={navLinkStyles} to="/valuesAndVision" disableRipple >Our Values And Vision</NavLink></Box>
      <Box mb={"1rem"}><ArrowCircleDownIcon /> <NavLink style={navLinkStyles} to="/workEnvironment" disableRipple >Work Environment</NavLink></Box>

        </Box>
  )
}

export default AboutSectionBar