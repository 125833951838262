// import React from "react";
// import banner from "../../Assets/Images/banner.webp";
// import { Box, Container, Typography } from "@mui/material";
// import CustomButton from "../../components/CustomButton";

// const Banner = () => {

//   return (
//     <>

//       <Box
//         sx={{
//           height:"100vh",
//           // backgroundImage: `url(${banner})`,
//           backgroundRepeat: "no-repeat",
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           lineHeight: "0",
//           position: "relative",
//           // clipPath: "polygon(0 0, 100% 0, 100% 91%, 0% 100%)",
//         }}
//       >
//         <Box
//           sx={{
//             // background: "linear-gradient(rgb(233, 243, 243), rgb(34, 75, 141))",
//             opacity: 0.6,
//             position: "absolute",
//             height: "100%",
//             top: "0",
//             width: "100%",
//             lineHeight: "0",
//           }}
//         ></Box>
//         <Container>
//           <Box
//             sx={{
//               width: { lg: "65%", xs: "100%" },
//               py: "12rem",
//               position: "relative",
//               color: "#000",
//             }}
//             data-aos="fade-up"
//           >
//             <Typography
//               sx={{
//                 fontSize: { sm: "3.7rem", xs: "1.8rem" },
//                 lineHeight: { sm: "4rem", xs: "2.3rem" },
//                 fontWeight: "bold",
//                 fontFamily:"KanitBold !important"
//               }}
//             >
//               Expert IP Services at Crescent Intellects
//             </Typography>
//             <Typography
//               sx={{
//                 py: "1rem",
//                 fontSize: { sm: "2rem", xs: "1.2rem" },
//                 fontWeight: "bold",
//                 fontFamily:"KanitBold !important"
//               }}

//             >
//               Experience top-notch IP services at Crescent Intellects, a
//               reputable legal business specialized in providing expert solutions
//               for all your intellectual property needs.
//             </Typography>
//             <CustomButton text={"Get Advice"} color={"#fff"}/>
//           </Box>
//         </Container>
//       </Box>
//       {/* <Container  data-aos="fade-up">
//         <Typography
//           sx={{
//             py: "6rem",
//             fontSize: { sm: "2rem", xs: "1.2rem" },
//             fontWeight: "bold",
//             fontFamily:"KanitBold !important"
//           }}
//         >
//           Experience top-notch IP services at Crescent Intellects, a reputable
//           legal business specialized in providing expert solutions for all your
//           intellectual property needs.
//         </Typography>
//       </Container> */}
//     </>
//   );
// };

// export default Banner;
import React, { useEffect, useRef } from "react";
import slider_1 from "../../Assets/Images/slider_1.png";
import slider_2 from "../../Assets/Images/Picture2.png";
import slider_3 from "../../Assets/Images/Picture3.png";
import slider_4 from "../../Assets/Images/Picture4.png";
import slider_5 from "../../Assets/Images/Picture5.png";
import slider_6 from "../../Assets/Images/Picture6.png";
import { Box, Container, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Banner = () => {
  const bannerData = [
    {
      id: 1,
      images: slider_1,
      color: "#fff",
      title:"Welcome to Crescent Intellects",
      desc: "Elevating Your Intellectual Property Journey",
    },
    {
      id: 2,
      images: slider_2,
      color: "#fff",
      desc: "Safeguarding Brilliance, Unleashing Potential ",
    },
    {
      id: 3,
      images: slider_3,
      color: "#fff",
      textAligh: "right",
      desc: "Crafted Solutions for Every Intellectual Ambition ",
    },
    {
      id: 4,
      images: slider_4,
      color: "#fff",
      textAligh: "right",
      desc: "Where Expertise Meets Elegance",
    },
    {
      id: 5,
      images: slider_5,
      color: "#fff",
      // textAligh:"right",
      desc: "Excellence as a Habit, Success as a Legacy ",
    },
    {
      id: 6,
      images: slider_6,
      color: "#fff",
      // textAligh:"right",
      desc: "Begin Your Odyssey Today",
    },
  ];
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiper = swiperRef.current.swiper;

    const slideInterval = setInterval(() => {
      if (swiper.isEnd) {
        swiper.slideTo(0, 0, false); // Instantly go to the first slide without transition
      } else {
        swiper.slideNext(0, false); // Instantly go to the next slide without transition
      }
    }, 4000); // Adjust the delay as needed

    return () => {
      clearInterval(slideInterval);
    };
  }, []);
  return (
    <>
      <Box position={"relative"} lineHeight={0} color={"#fff"}>
        <Swiper
          ref={swiperRef}
          spaceBetween={30}
          centeredSlides={true}
          // autoplay={{
          //   delay: 3500,
          //   speed:0,
          //   disableOnInteraction: false,
          // }}
          loop={true} // Set loop to true to seamlessly loop the slides
          speed={0} // Set speed to 0 for instant slide transition
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
          style={{ position: "relative" }}
        >
          {bannerData.map((e, index) => (
            <SwiperSlide key={index}>
              <Box
                sx={{
                  background:
                    "linear-gradient(rgb(0 0 0 / 59%), rgb(34 75 141 / 0%))",
                  position: "absolute",
                  height: "100%",
                  top: "0",
                  width: "100%",
                  lineHeight: "0",
                }}
              />
              <Box
                // component={"img"}
                // src={e.images}
                sx={{
                  width: "100%",
                  backgroundImage: `url(${e.images})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",

                  height: "100vh",
                }}
              />
              <Container>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    ml: { md: "0", xs: "2rem" },
                    right: e.textAligh ? "3rem" : "",
                    transform: "translateY(-50%)",
                  }}
                >
                  {e.title &&<Typography
                    sx={{
                      fontSize: { sm: "2.5rem", xs: "1.8rem" },
                      lineHeight: { md: "3.5rem", xs: "2.2rem" },
                      fontWeight: "bold",
                      // textAlign: { md: e.textAligh },
                    }}
                  >
                  {e.title}
                  </Typography>}
                  <Typography
                    sx={{
                      fontSize: { sm: "2rem", xs: "1.2rem" },
                      width: { md: "500px", xs: "70%" },
                      fontWeight: "bold",
                      // textAlign: { md: e.textAligh },
                      color: e.color,
                    }}
                  >
                    {e.desc}
                  </Typography>
                </Box>
              </Container>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </>
  );
};

export default Banner;
