import React, { useEffect } from "react";
import ScrollButton from "../components/ScrollButton";
import Navbar from "../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../components/Footer";
import Title from "../components/Title";
import ServiceSectionBar from "./Services/ServiceSectionBar";
import portfolio from "../Assets/Images/portfolio.jpeg";

const Portfolio = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <Grid container spacing={4} mb={"3rem"}>
          <Grid item md={8.5} sm={12} xs={12}>
            <Typography
              sx={{
                color: "#002856",
                fontSize: "1.1rem",
                fontWeight: "600",
                pb: "0.2rem",
              }}
            >
              IP Portfolio Management
            </Typography>
            <Box
              sx={{
                "& p": {
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  pb: "1.5rem",
                  textAlign: { lg: "justify", xs: "inherit" },
                  color: "#000",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  fontSize: "1.1rem !important",
                  color: "#000 !important",
                }}
              >
                At Crescent Intellects, we understand that managing a diverse
                portfolio of intellectual property (IP) assets can be a complex
                and challenging task. That is why we offer comprehensive IP
                Portfolio Management services designed to streamline your IP
                operations, optimize your asset portfolio, and maximize the
                value of your intellectual property investments. With our
                expertise in IP law, strategic planning, and technology, we
                provide tailored solutions to meet your specific needs and
                objectives.
              </Typography>
              <Typography
                component={"img"}
                width={"100%"}
                src={portfolio}
                pb={"1rem"}
              />
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Portfolio Audit and Assessment:
                </b>
                <br />
                We begin by conducting a thorough audit and assessment of your
                existing IP portfolio. Our team of experts evaluates the scope,
                quality, and strategic alignment of your patents, trademarks,
                copyrights, and trade secrets. We identify strengths,
                weaknesses, and opportunities for improvement, providing you
                with valuable insights to inform strategic decision-making.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Portfolio Optimization Strategy:
                </b>
                <br />
                Based on the results of the portfolio audit, we develop a
                customized optimization strategy tailored to your business goals
                and market dynamics. Whether it is pruning underperforming
                assets, expanding into new markets, or diversifying your IP
                holdings, we help you prioritize investments and allocate
                resources effectively to maximize the value of your portfolio.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  IP Acquisition and Licensing:
                </b>
                <br />
                We assist you in identifying and acquiring valuable IP assets
                through strategic acquisitions, partnerships, or licensing
                agreements. Our team conducts thorough due diligence to evaluate
                the potential risks and opportunities associated with IP
                transactions, ensuring that you make informed decisions that
                align with your business objectives.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Renewal Management:
                </b>
                <br />
                Managing the renewal and maintenance of your IP assets is
                essential for preserving their value and enforceability. We
                provide comprehensive renewal management services, tracking
                renewal deadlines, coordinating payments, and ensuring
                compliance with local laws and regulations. By proactively
                managing renewals, we help you avoid lapses in protection and
                maintain a strong and enforceable IP portfolio.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Licensing and Monetization Strategies:
                </b>
                <br />
                Monetizing your IP assets through licensing, technology
                transfer, or royalty agreements can unlock new revenue streams
                and enhance the value of your portfolio. We develop customized
                licensing and monetization strategies tailored to your unique IP
                assets and business objectives. Whether you are looking to
                commercialize patented inventions, sublicense trademarks, or
                monetize copyrighted works, we help you negotiate favourable
                agreements and maximize your licensing revenue.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  IP Enforcement and Litigation Support:
                </b>
                <br />
                Protecting your IP rights against infringement and unauthorized
                use requires proactive enforcement and, if necessary, legal
                action. Our experienced litigation support team provides expert
                assistance in IP enforcement, from cease-and-desist letters and
                negotiation of settlements to litigation and dispute resolution.
                We work tirelessly to defend your IP rights and safeguard your
                competitive advantage in the marketplace.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  IP Strategy Development and Advisory:
                </b>
                <br />
                Developing a robust IP strategy is essential for achieving your
                business goals and maintaining a competitive edge. We offer
                strategic advisory services to help you develop and implement an
                effective IP strategy aligned with your long-term objectives.
                Our team provides guidance on patent filing strategies,
                trademark portfolio management, copyright protection, trade
                secret management, and IP risk mitigation, empowering you to
                make informed decisions that drive business success.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <ServiceSectionBar />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Portfolio;
