import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import ServiceSectionBar from "../Services/ServiceSectionBar";
import designImg from "../../Assets/Images/Design.png"

const Design = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <Grid container spacing={4} mb={"3rem"}>
          <Grid item md={8.5} sm={12} xs={12}>
            <Typography
              sx={{
                color: "#002856",
                fontSize: "1.1rem",
                fontWeight: "600",
                pb: "0.2rem",
              }}
            >
             Design
            </Typography>
            <Box
              sx={{
                "& p": {
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  pb: "1.5rem",
                  textAlign: { lg: "justify", xs: "inherit" },
                  color: "#4d4d4d",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  fontSize: "1.1rem !important",
                  color: "#000 !important",
                }}
              >
               Crescent Intellects offers a range of design services aimed at helping clients protect and manage their industrial designs effectively. Here are the details of the design services provided by our firm:
              </Typography>
              <Typography
                component={"img"}
                width={"100%"}
                src={designImg}
                pb={"1rem"}
              />
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Design Searches and Clearance:
                </b>
                <br />
                Crescent Intellects conducts thorough design searches to assess the novelty and registrability of a proposed design. This involves evaluating existing designs to identify potential conflicts and ensure that the client's design is distinctive and eligible for protection.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Design Filing and Registration:
                </b>
                <br />
                Our firm manages the entire design registration process. We assist clients in preparing and filing design applications, ensuring compliance with legal requirements. Crescent Intellects communicates with design offices, addresses any office actions, and navigates the registration process to secure design protection.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Design Renewals and Maintenance:
                </b>
                <br />
                Crescent Intellects provides ongoing support for the renewal and maintenance of registered designs. We proactively remind clients of renewal deadlines, manage the necessary paperwork, and ensure that all fees are paid to maintain the validity and protection of their registered designs.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Design Portfolio Management:
                </b>
                <br />
                Our strategic design portfolio management involves regular assessments of the client's design assets. We identify valuable designs, evaluate their alignment with business goals, and make recommendations on maintenance, licensing, or divestiture to optimize the overall design portfolio.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
               Design Oppositions and Cancellations:
                </b>
                <br />
                In cases of potential conflicts, Crescent Intellects represents clients in design opposition proceedings, employing legal strategies to protect their designs from conflicting applications. We also handle cancellations to challenge existing design registrations that may pose a threat to our clients' design rights.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Design Assignments and Licensing:
                </b>
                <br />
                Crescent Intellects facilitates the transfer of design ownership through assignments and ensures compliance with legal requirements. Additionally, our team assists in drafting and negotiating design licensing agreements, ensuring that licensing arrangements align with the client's business strategy and goals.
              </Typography>
              {/* <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Customized Design Training and Workshops:
                </b>
                <br />
                Crescent Intellects provides tailored training and workshops on design-related topics. These sessions are designed to educate clients and their teams about the significance of design protection, best practices for registration, and strategic approaches to building and managing a strong and effective design portfolio.
              </Typography> */}
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Design Infringement Analysis:
                </b>
                <br />
                Our firm conducts design infringement analyses to assess whether a third party is potentially infringing on a client's registered design. This involves a detailed examination of the design elements and legal considerations to provide clients with insights into possible infringement issues.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Design Due Diligence:
                </b>
                <br />
                For transactions involving intellectual property, Crescent Intellects conducts thorough design due diligence. This includes assessing the strength, validity, and potential risks associated with target company designs, providing clients with valuable insights to make informed decisions during mergers, acquisitions, or other business transactions.
              </Typography>
            
              <Typography>
              Crescent Intellects is committed to delivering comprehensive, strategic, and client-focused design services to protect and enhance the value of our clients' industrial designs.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <ServiceSectionBar />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Design;
