import React, { useEffect } from "react";
import Navbar from "../../components/header/Navbar";
import Banner from "./Banner";
import { Box } from "@mui/material";
// import About from "../About/About";
// import PracticeArea from "../PracticeArea/PracticeArea";
// import Footer from "../../components/Footer";
import ScrollButton from "../../components/ScrollButton";
// import Team from "../../components/Team";

const Home = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
     
    >
    <ScrollButton/>
      <Navbar position="fixed" bg="transparent"/>
      <Banner />
      {/* <About/>
      <PracticeArea/>
      <Team/> */}
      {/* <Service/> */}
      {/* <Form/>
    
      <Footer/> */}
    </Box>
  );
};

export default Home;
