import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container } from "@mui/material";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
// import ServiceSectionBar from "./ServiceSectionBar";
import ServiceBox from "./ServiceBox";

const Services = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "auto",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <ServiceBox/>
       
      </Container>
      <Footer />
    </Box>
  );
};

export default Services;
