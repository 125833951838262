import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import PatentSectionBar from "./PatentSectionBar";
import patentProsecution from "../../Assets/Images/PatentProsecution.png"



const PatentProsecution = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <Box>
        </Box>
       <Grid container spacing={4} mb={"3rem"}>
       <Grid item md={8.5} sm={12} xs={12}>
        <Typography sx={{color:"#002856",fontSize:"1.1rem",fontWeight:"600",pb: "0.2rem",}}>Patent Prosecution</Typography>
   <Box sx={{
   "& p":{
    fontSize:"1.1rem",
      fontWeight: "500",
      pb:"1.5rem",
      textAlign:{lg:"justify",xs:"inherit"},
      color:"#000"
   }
    }}>
   <Typography sx={{fontWeight:"500 !important",fontSize:"1.1rem !important",color:"#000 !important"}}>
   Patent prosecution is a crucial phase in the life cycle of a patent, involving interactions with patent offices to secure the grant of a patent. Crescent Intellects offers comprehensive patent prosecution services to guide clients through this complex process. Here are the key details about patent prosecution services provided by our firm:
  </Typography>
  <Typography component={"img"} width={"100%"} src={patentProsecution} pb={"1.5rem"}/>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Filing of Patent Application:</b>
   <br/>
   Crescent Intellects initiates the patent prosecution process by preparing and filing a patent application on behalf of the client. This involves drafting a detailed specification, claims, and any necessary drawings that describe the invention in a manner that satisfies the requirements of the patent office.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Patent Office Examination:</b>
   <br/>
   After filing, the patent application undergoes examination by the relevant patent office. Crescent Intellects engages with patent examiners to address any queries, concerns, or objections raised during the examination process. Our goal is to ensure that the patent application complies with legal requirements and is granted patent protection.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Office Action Responses:</b>
   <br/>
   When a patent examiner issues an office action outlining objections or requirements for amendment, Crescent Intellects prepares detailed and persuasive responses on behalf of the client. These responses address the examiner's concerns and may involve amending claims, providing clarifications, or presenting arguments to overcome rejections.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Amendments and Claim Drafting:</b>
   <br/>
   Our team is skilled in crafting strategic amendments to the patent application, including modifications to claims or other parts of the specification. We ensure that amendments are made to maximize the chances of patent grant while maintaining the scope of protection desired by the client.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Interviews with Examiners:</b>
   <br/>
   Crescent Intellects conducts interviews with patent examiners as part of the prosecution process. These interviews provide an opportunity to discuss the application directly with the examiner, address any concerns, and potentially expedite the examination process.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Continuation and Divisional Applications:</b>
   <br/>
   In cases where additional protection or modifications are needed, Crescent Intellects assists clients in filing continuation or divisional applications. Continuation applications allow for further development or expansion of the original invention, while divisional applications separate distinct inventions from a single initial application.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Appeals and Petitions:</b>
   <br/>
   If disagreements persist with the patent examiner, Crescent Intellects may pursue appeals or petitions to higher levels within the patent office. Our team is experienced in preparing persuasive arguments and documentation to present the case effectively.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Grant and Issuance:</b>
   <br/>
   Upon successful completion of the prosecution process, Crescent Intellects ensures that the patent is granted and issued by the patent office. This includes the payment of any necessary fees and the fulfillment of administrative requirements.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Post-Grant Proceedings:</b>
   <br/>
   After patent issuance, Crescent Intellects assists clients in navigating post-grant proceedings, such as oppositions or validity challenges. We provide strategic advice and representation to protect the granted patent against potential threats.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Global Prosecution Strategy:</b>
   <br/>
   Crescent Intellects develops and executes global patent prosecution strategies, considering the unique requirements of multiple jurisdictions. This involves coordinating filings, responses, and strategies to optimize the protection of a client's invention worldwide.
  </Typography>
  <Typography>
  Throughout the patent prosecution process, Crescent Intellects remains committed to providing strategic guidance, clear communication, and effective representation. Our goal is to secure robust patent protection for our clients, enabling them to leverage their innovations for competitive advantage in the marketplace.
  </Typography>


   </Box>
   </Grid>
        <Grid item md={3.5} sm={12} xs={12}>
        <PatentSectionBar/>
        </Grid>
       </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default PatentProsecution;
