import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home/Home.js';
import AboutPage from './pages/AboutPage/AboutPage';
import Vision from './pages/Vision/Vision';
import Contact from './pages/Contact/Contact';
import Services from './pages/Services/Services';
import WorkEnvironment from './pages/WorkEnvironment/WorkEnvironment';
import PatentSearch from './pages/Patent/PatentSearch';
import PatentProsecution from './pages/Patent/PatentProsecution';
import TrademarkSearch from './pages/Trademark/TrademarkSearch';
import Design from './pages/Design/Design';
import Copyright from './pages/Copyright/Copyright';
import Career from './pages/Career/Career';
import ChooseCareer from './pages/Career/ChooseCareer';
import HowToApply from './pages/Career/HowToApply';
import CurrentOppurtunities from './pages/Career/CurrentOppurtunities';
import AOS from "aos";
import "aos/dist/aos.css";
// import FillingAndRegistration from './pages/Trademark/FillingAndRegistration';
import TradeSecret from './pages/TradeSecret';
import Portfolio from './pages/Portfolio';
import Deligence from './pages/Deligence';
import Licensing from './pages/Licensing';
import Litigations from './pages/Litigations';
import Domain from './pages/Domain';
import TrainingAndWorkshop from './pages/TrainingAndWorkshop';

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route exact path="/valuesAndVision" element={<Vision />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/service" element={<Services />} />
          <Route exact path="/workEnvironment" element={<WorkEnvironment />} />
          <Route exact path="/patent" element={<PatentSearch />} />
          <Route exact path="/patent/prosecution" element={<PatentProsecution />} />
          <Route exact path="/trademark" element={<TrademarkSearch />} />
          <Route exact path="/design" element={<Design />} />
          <Route exact path="/copyright" element={<Copyright />} />
          <Route exact path="/career" element={<Career />} />
          <Route exact path="/choose" element={<ChooseCareer />} />
          <Route exact path="/current-oppurtunities" element={<CurrentOppurtunities />} />
          <Route exact path="/apply" element={<HowToApply />} />
          {/* <Route exact path="/filling-registration" element={<FillingAndRegistration />} /> */}
          <Route exact path="/trade-secret" element={<TradeSecret />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/diligence" element={<Deligence />} />
          <Route exact path="/licensing" element={<Licensing />} />
          <Route exact path="/litigation" element={<Litigations />} />
          <Route exact path="/domain" element={<Domain />} />
          <Route exact path="/training" element={<TrainingAndWorkshop />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
