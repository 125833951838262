import React, { useEffect } from 'react'
import ScrollButton from '../../components/ScrollButton'
import Navbar from '../../components/header/Navbar'
import { Box, Container, Grid, Typography } from '@mui/material'
import Footer from '../../components/Footer'
import Title from '../../components/Title'
import AboutSectionBar from '../AboutPage/AboutSectionBar'
import values from "../../Assets/Images/values.png"

const Vision = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <Box
    sx={{
      "& p,li": {
        fontFamily: "KanitRegular!important",
      },
    }}
  >
  <ScrollButton/>
    <Navbar />
<Container data-aos="fade-up">
<Box py={"3rem"} textAlign={"center"}>
    <Title textTransform={"capitalize"} title={"About Us"} />
    </Box>
    <Grid container spacing={4} mb={"3rem"}>
        <Grid item md={9} sm={12} xs={12}>
        <Typography sx={{color:"#002856",fontSize:"1.1rem",fontWeight:"600",pb: "0.2rem",}}>Our Values And Vision</Typography>
   <Box sx={{
   "& p":{
    fontSize:"1.1rem",
      fontWeight: "500",
      pb:"1.5rem",
      textAlign:{lg:"justify",xs:"inherit"},
      color:"#000"
   }
    }}>
   <Typography sx={{fontWeight:"500 !important",fontSize:"1.1rem !important",color:"#000 !important"}}>
   Crescent Intellects prides itself on embodying a set of core qualities that set us apart in the realm of intellectual property services. Here are some key qualities that define our firm:
  </Typography>
  <Typography component={"img"} width={"100%"} src={values} pb={"1.5rem"}/>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Expertise and Specialization:</b>
   <br/>
  At Crescent Intellects, our vision is rooted in fostering a culture of innovation and creativity, where intellectual property is not just safeguarded but serves as a catalyst for growth and development. We believe in empowering our clients to capitalize on their ideas, inventions, and creations by offering strategic guidance and unparalleled expertise in navigating the complexities of intellectual property law.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Client-Centric Approach:</b>
   <br/>
   We prioritize our clients and their unique needs. Our client-centric approach involves active listening, clear communication, and a commitment to understanding the specific challenges and goals of each individual or business we serve.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Innovation Advocates:</b>
   <br/>
   As a firm founded on the principles of innovation, we not only protect intellectual property but actively advocate for the advancement of creative and inventive endeavors. We believe in fostering an environment where innovation is celebrated and serves as a driving force for growth.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Strategic Guidance:</b>
   <br/>
   Crescent Intellects goes beyond traditional legal services by offering strategic guidance. We work collaboratively with clients to develop comprehensive intellectual property strategies that align with their business objectives, ensuring long-term success.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Global Perspective:</b>
   <br/>
   In an interconnected world, Crescent Intellects takes a global perspective. We understand the complexities of intellectual property rights across different jurisdictions, providing our clients with the necessary insights to navigate international markets effectively.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Ethical Standards:</b>
   <br/>
   Integrity is at the core of our operations. Crescent Intellects adheres to the highest ethical standards in all interactions. We prioritize transparency, honesty, and accountability to build trust with our clients and partners.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Continuous Learning and Adaptability:</b>
   <br/>
   The dynamic nature of intellectual property requires constant learning and adaptability. Crescent Intellects is committed to staying at the forefront of industry trends, technological advancements, and legal developments to provide our clients with cutting-edge solutions.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Efficiency and Timeliness:</b>
   <br/>
   Recognizing the importance of time in the world of intellectual property, Crescent Intellects is dedicated to delivering efficient and timely services. We streamline processes to ensure that our clients receive prompt and effective assistance.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Community Engagement:</b>
   <br/>
   Beyond our client relationships, Crescent Intellects actively engages with the broader community. We participate in educational initiatives, industry events, and forums to contribute to the understanding and development of intellectual property concepts.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Long-Term Partnerships:</b>
   <br/>
   Crescent Intellects seeks to establish enduring partnerships with clients. We view our relationships as collaborations that extend beyond immediate needs, fostering an ongoing commitment to the success and growth of those we serve.
  </Typography>

   </Box>
   </Grid>
        <Grid item md={3} sm={12} xs={12}>
        <AboutSectionBar/>
        </Grid>
       </Grid>
</Container>
    <Footer/>
  </Box>
  )
}

export default Vision