import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import CareerSectionBar from "./CareerSectionBar";
import carrer from "../../Assets/Images/carrer.png"




const CurrentOppurtunities = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "auto",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Career"} />
        </Box>
        <Box>
        </Box>
       <Grid container spacing={4} mb={"3rem"}>
        <Grid item md={8.5} sm={12} xs={12}>
        <Typography sx={{color:"#002856",fontSize:"1.6rem",fontWeight:"600",pb: "0.2rem",}}>Current Opportunities</Typography>
  
        <Box
          sx={{
            "& p": {
              fontSize: "1.2rem",
              fontWeight: "500",
              pb: "1.5rem",
              textAlign: { lg: "justify", xs: "inherit" },
              color:"#000"
              
            },
          }}
        >
          <Typography>
          Explore exciting career opportunities at Crescent Intellects across various domains, including patent services, trademark services, copyright services, design services, legal, and administrative roles. Join us on our journey of intellectual property excellence and contribute to shaping the future of innovation.
          </Typography>
          <Typography component={"img"} width={"100%"} src={carrer} pb={"1rem"}/>
    
        </Box>
        </Grid>
        <Grid item md={3.5} sm={12} xs={12}>
        <CareerSectionBar/>
        </Grid>
       </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default CurrentOppurtunities;
