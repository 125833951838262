import { Typography } from '@mui/material'
import React from 'react'

const Title = ({title,textTransform,letterSpacing}) => {
  return (
    <Typography
    sx={{
      fontSize: {sm:"2.8rem",xs:"2rem"},
      // fontWeight: "500",
      lineHeight:{md:"1.3",xs:"1.3"},
      mb:{md:"0",xs:"0.5rem"},
      textTransform:textTransform,
      fontFamily:"KanitRegular !important",
      letterSpacing:letterSpacing,
      color:"#002856"
    }}
  >
   {title}
  </Typography>
  )
}

export default Title