import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import ServiceSectionBar from "../Services/ServiceSectionBar";
import CopyrightImg from "../../Assets/Images/Copyright.png";

const Copyright = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <Grid container spacing={4} mb={"3rem"}>
          <Grid item md={8.5} sm={12} xs={12}>
            <Typography
              sx={{
                color: "#002856",
                fontSize: "1.1rem",
                fontWeight: "600",
                pb: "0.2rem",
              }}
            >
             Copyright
            </Typography>
            <Box
              sx={{
                "& p": {
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  pb: "1.5rem",
                  textAlign: { lg: "justify", xs: "inherit" },
                  color: "#000",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  fontSize: "1.1rem !important",
                  color: "#000 !important",
                }}
              >
               Crescent Intellects provides a range of comprehensive copyright services to help clients protect and manage their creative works. Here are the details of the copyright services offered by our firm:
              </Typography>
              <Typography
                component={"img"}
                width={"100%"}
                src={CopyrightImg}
                pb={"1rem"}
              />
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Copyright Registration:
                </b>
                <br />
                Crescent Intellects assists clients in the registration of their creative works with relevant copyright offices. We guide clients through the application process, ensuring compliance with legal requirements and maximizing the protection of their intellectual property.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Copyright Searches and Clearance:
                </b>
                <br />
                Our firm conducts copyright searches to assess the originality and potential conflicts of clients' creative works. This involves evaluating existing copyrights to identify any potential issues and ensuring that the client's work is distinct and eligible for copyright protection.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Copyright Licensing and Permissions:
                </b>
                <br />
                Crescent Intellects facilitates the drafting and negotiation of copyright licensing agreements. We help clients establish clear terms and conditions for the use of their creative works by third parties, ensuring that licensing arrangements align with the client's business objectives.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                 Copyright Enforcement and Litigation:
                </b>
                <br />
                In cases of copyright infringement, Crescent Intellects represents clients in enforcement actions and litigation. Our legal team employs strategies to protect clients' copyrights, pursuing legal remedies against unauthorized use and defending clients' rights in court if necessary.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Digital Millennium Copyright Act (DMCA) Compliance:
                </b>
                <br />
                Crescent Intellects assists clients in complying with the DMCA, addressing issues related to online copyright infringement. This includes drafting and submitting DMCA takedown notices to online platforms hosting infringing content and navigating the legal processes associated with online copyright protection.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Fair Use Analysis:
                </b>
                <br />
                Our firm provides fair use analyses to assess whether the use of copyrighted material by third parties falls within the legal bounds of fair use. This involves evaluating factors such as purpose, nature, amount, and effect on the market to determine the applicability of fair use defenses.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Copyright Due Diligence:
                </b>
                <br />
                For transactions involving intellectual property, Crescent Intellects conducts thorough copyright due diligence. This includes assessing the ownership, validity, and potential risks associated with target company copyrights, providing clients with valuable insights for informed decision-making during mergers, acquisitions, or other business transactions.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Copyright Portfolio Management:
                </b>
                <br />
                Crescent Intellects offers strategic copyright portfolio management services. We assist clients in identifying and prioritizing their copyrighted works, making decisions on licensing, renewal, and other aspects to optimize the overall management of their copyright assets.
              </Typography>
              {/* <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Customized Copyright Training and Workshops:
                </b>
                <br />
                Our firm provides tailored training and workshops on copyright-related topics. These sessions are designed to educate clients and their teams about the significance of copyright protection, best practices for registration, and strategic approaches to building and managing a strong and effective copyright portfolio.
              </Typography>
            
              <Typography>
              Crescent Intellects is committed to delivering comprehensive, strategic, and client-focused copyright services to safeguard and maximize the value of our clients' creative works.
              </Typography> */}
            </Box>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <ServiceSectionBar />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Copyright;
