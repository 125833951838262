import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import TrademarkSectionBar from "./TrademarkSectionBar";
import trademarkSearch from "../../Assets/Images/TrademarkSearch.png"



const TrademarkSearch = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <Box>
        </Box>
       <Grid container spacing={4} mb={"3rem"}>
       <Grid item md={8.5} sm={12} xs={12}>
        <Typography sx={{color:"#002856",fontSize:"1.1rem",fontWeight:"600",pb: "0.2rem",}}>Trademark</Typography>
   <Box sx={{
   "& p":{
    fontSize:"1.1rem",
      fontWeight: "500",
      pb:"1.5rem",
      textAlign:{lg:"justify",xs:"inherit"},
      color:"#000"
   }
    }}>
   <Typography sx={{fontWeight:"500 !important",fontSize:"1.1rem !important",color:"#000 !important"}}>
   Crescent Intellects offers a comprehensive suite of trademark services to assist clients in protecting and managing their brand identities. Here are the details of the trademark services provided by our firm:
  </Typography>
  <Typography component={"img"} width={"100%"} src={trademarkSearch} pb={"1.5rem"}/>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Trademark Search and Clearance:</b>
   <br/>
   Crescent Intellects conducts extensive searches to evaluate the availability and registrability of a proposed trademark. Through in-depth analysis of existing trademarks and potential conflicts, we provide clients with a clear understanding of the trademark landscape, allowing them to make informed decisions before proceeding with the registration process.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Trademark Filing and Registration:</b>
   <br/>
   Our firm manages the entire trademark registration process. We assist clients in preparing and filing trademark applications, ensuring compliance with legal requirements. Crescent Intellects communicates with trademark offices, addresses office actions, and navigates the registration process to maximize the chances of successful registration.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>State-of-the-Art Search:</b>
   <br/>
   State-of-the-art searches involve a comprehensive exploration of existing technologies and advancements within a specific technical field. These searches are not limited to a particular invention and are useful for staying informed about the latest developments in a given industry.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Trademark Renewals and Maintenance:</b>
   <br/>
   Crescent Intellects provides ongoing support for the renewal and maintenance of registered trademarks. We proactively remind clients of renewal deadlines, manage the necessary paperwork, and ensure that all fees are paid to maintain the validity and protection of their trademarks.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Trademark Portfolio Management:</b>
   <br/>
   Our strategic trademark portfolio management involves regular assessments of the client's trademark assets. We identify valuable trademarks, evaluate their alignment with business goals, and make recommendations on maintenance, licensing, or divestiture to optimize the overall portfolio.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Trademark Oppositions and Cancellations:</b>
   <br/>
   In cases of potential conflicts, Crescent Intellects represents clients in trademark opposition proceedings, leveraging legal strategies to protect their trademarks from conflicting applications. We also handle cancellations to challenge existing registrations that may pose a threat to our clients' trademark rights.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Trademark Assignments and Licensing:</b>
   <br/>
   Crescent Intellects facilitates the transfer of trademark ownership through assignments and ensures compliance with legal requirements. Additionally, our team assists in drafting and negotiating trademark licensing agreements, ensuring that licensing arrangements align with the client's business strategy and goals.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Trademark Watch Services:</b>
   <br/>
   Our trademark watch services involve continuous monitoring of newly filed trademarks and potential infringements. Early detection allows us to provide timely alerts to clients, enabling them to take proactive measures to protect their trademarks and enforce their rights.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Trademark Due Diligence:</b>
   <br/>
   For transactions involving intellectual property, Crescent Intellects conducts thorough trademark due diligence. This includes assessing the strength, validity, and potential risks associated with target company trademarks, providing clients with valuable insights to make informed decisions during mergers, acquisitions, or other business transactions.
  </Typography>
   <Typography>
   <b style={{fontSize:"1.1rem",color:"#002856"}}>Domain Name Disputes:</b>
   <br/>
   Crescent Intellects assists clients in resolving domain name disputes, employing strategies to address cases of cybersquatting and trademark infringement online. Our services encompass dispute resolution proceedings, negotiation, and legal action when necessary to safeguard clients' online brand presence.
  </Typography>
  



   </Box>
   </Grid>
        <Grid item md={3.5} sm={12} xs={12}>
        <TrademarkSectionBar/>
        </Grid>
       </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default TrademarkSearch;
