import {
    Box,
    Button,
    Container,
    Grid,
    TextField,
    Typography,
  } from "@mui/material";
  import React from "react";
  import { useFormik } from "formik";
  import * as Yup from "yup";
  
 
  
  const Form_1 = ({display}) => {
    const getInitialValues = () => {
      return {
        name: "",
        email: "",
        message: "",
        phone: "",
        service: "",
      };
    };
    const validationSchema = Yup.object({
      name: Yup.string()
        .trim("Name should not contain spaces")
        .required("Required"),
      email: Yup.string()
        .trim("Email should not contain spaces")
        .email("Invalid email")
        .required("Required"),
      message: Yup.string()
        .trim("Message should not contain spaces")
        .required("Required"),
      service: Yup.string()
        .trim("Service should not contain spaces")
        .required("Required"),
      phone: Yup.number().required("Required"),
    });
    const initialValues = getInitialValues();
    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (
        values,
        { setErrors, setSubmitting, resetForm, errors }
      ) => {
        if (errors) {
          setErrors(errors);
        }
      },
    });
    return (
      <Container>
        <Grid container spacing={3}>
          <Grid item md={12} sm={12} xs={12}>
            <Box>
              <Typography
                sx={{
                  fontSize: { sm: "2rem", xs: "2rem" },
                //   textAlign: "center",
                  fontWeight: "600",
                  color:"#002856",
                  fontFamily:"Kanit !important",
                }}
              >
                Get in touch with us
              </Typography>
            </Box>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        mb: "0.1rem",
                        color: "#000",
                      }}
                    >
                      Name<sup style={{ color: "red" }}>*</sup>
                    </Typography>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="name"
                      name="name"
                      type="text"
                      className="bin1"
                      placeholder="Enter Your Name"
                      variant="standard"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <Typography variant="body1"  className="error">
                        {formik.errors.name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        mb: "0.1rem",
                        color: "#000",
                      }}
                    >
                      Email<sup style={{ color: "red" }}>*</sup>
                    </Typography>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="email"
                      name="email"
                      type="email"
                      className="bin1"
                      placeholder="Enter Your Email"
                      variant="standard"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <Typography variant="body1"  className="error">
                        {formik.errors.email}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        mb: "0.1rem",
                        color: "#000",
                      }}
                    >
                      Phone<sup style={{ color: "red" }}>*</sup>
                    </Typography>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="phone"
                      name="phone"
                      type="number"
                      className="bin1"
                      placeholder="Enter Your Phone No."
                      variant="standard"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <Typography variant="body1"  className="error">
                        {formik.errors.phone}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        mb: "0.1rem",
                        color: "#000",
                      }}
                    >
                      Service<sup style={{ color: "red" }}>*</sup>
                    </Typography>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="service"
                      name="service"
                      type="text"
                      className="bin1"
                      placeholder="Enter Your Service"
                      variant="standard"
                      value={formik.values.service}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.service && formik.errors.service && (
                      <Typography variant="body1"  className="error">
                        {formik.errors.service}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        mb: "0.1rem",
                        color: "#000",
                      }}
                    >
                      Message<sup style={{ color: "red" }}>*</sup>
                    </Typography>
  
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="message"
                      name="message"
                      type="text"
                      className="bin1"
                      placeholder="Message"
                      variant="standard"
                      multiline={8}
                      value={formik.values.message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.message && formik.errors.message && (
                      <Typography variant="body1"  className="error">
                        {formik.errors.message}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        fontSize: "15px",
                        // mt: "0.1rem",
                        color: "#000",
                      }}
                      component={"span"}
                    >
                     By clicking the submit button, you give Crescent Intellects Services consent to store and process the personal information submitted above. Crescent Intellects is committed to protecting and respecting your privacy, we'll only use your information to provide the products and services you requested from us.
                    </Typography>
                  </Grid>
                </Grid>
                <Box textAlign={"center"}>
                  <Button
                    disableRipple
                    type="submit"
                    sx={{
                      mt: "1.5rem",
                      padding: "5px 25px !important",
                      color: "#fff !important",
                      fontWeight: "600 !important",
                      fontSize: "16px !important",
                      fontFamily: "'KanitRegular', sans-serif",
                      transition: "0.2s all !important",
                      backgroundColor: "rgb(34, 75, 141)",
                      textTransform: "capitalize",
                      borderRadius: "5px",
                      border: "2px solid transparent",
                      "&:hover": {
                        transition: "0.2s all !important",
                        border: "2px solid rgb(34, 75, 141)",
                        backgroundColor: "transparent",
                        color: "rgb(34, 75, 141) !important",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Container>
    );
  };
  
  export default Form_1;
  