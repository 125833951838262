import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link, NavLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Container } from "@mui/material";
import icon from "../../Assets/Images/logo_2.png";


const drawerWidth = 300;

function DrawerAppBar(props) {
  const { window, position, bg } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const navLinkStyles = ({ isActive }) => {
    return {
      borderBottom: isActive ? "1px solid #fff" : "1px solid transparent",
      transition: "0.3s all !important",
      // rgb(34, 75, 141)
    };
  };
  return (
    <Box
      sx={{
        background: !bg ? "rgb(34, 75, 141)" : "transparent",
        position: position ? position : "sticky !important",
        top: "0",
        zIndex: "2",
        width: "100%",
      }}
    >
      <Container
        sx={{
          "& a": {
            textDecoration: "none",
          },
        }}
      >
        <CssBaseline />
        <AppBar
          component="nav"
          sx={{
            position: "inherit",
            backgroundColor: "transparent",
            boxShadow: "none",
            py: "0.6rem",
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between", p: "0 !important" }}>
            <Link to={"/"} style={{ display: "flex",alignItems: "center",gap:"0.1rem"}}>
              {" "}
              <Typography
                  lineHeight={0}
                  component={"img"}
                  src={icon}
                  sx={{ width: { sm: "65px", xs: "35px" } }}
                />
              <Typography
                sx={{
                  color: "#fff",
                  pt:"0.7rem",
                  fontWeight: "700",
                  fontSize: { sm: "1.5rem", xs: "1.2rem" },
                 lineHeight:{sm:"1.3rem",xs:"1.1rem"},
                  fontFamily: "KanitRegular !important",
                  
                }}
              >
                {" "}
             
                Crescent Intellects
                <br/>
                <Typography component={"span"} sx={{
                   fontFamily: "KanitRegular !important",
                   fontSize: { sm: "14px", xs: "12px" },
                   fontStyle:"italic",
                   fontWeight:"500"
                }}>Transforming Ideas into Assets</Typography>
               
              </Typography>
            </Link>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { lg: "none", textAlign: "end" } }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              sx={{
                display: { md: "none", lg: "flex", sm: "none", xs: "none" },
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <NavLink
                  style={navLinkStyles}
                  to="/"
                  disableRipple
                  className="btn"
                >
                  Home
                </NavLink>
                <NavLink
                  style={navLinkStyles}
                  to="/about"
                  disableRipple
                  className="btn"
                >
                  About Us
                </NavLink>
                <NavLink
                  style={navLinkStyles}
                  to="/service"
                  disableRipple
                  className="btn"
                >
                  Services
                </NavLink>
                <NavLink style={navLinkStyles} to="/career" disableRipple className="btn">Career</NavLink>
                <NavLink
                  style={navLinkStyles}
                  to="/contact"
                  disableRipple
                  className="btn"
                >
                  Contact Us
                </NavLink>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { md: "block", lg: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                background: "rgb(34, 75, 141)",
                padding: "1rem",
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "7px",
                right: "7px",
                background: "#fff",
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleDrawerToggle}
            >
              <CloseIcon sx={{ color: "#000" }} />
            </Box>
            <Box
              sx={{
                display: "grid",
                gap: "1rem",
                justifyContent: "center",
                textAlign: "center",
                mt: "2rem",
              }}
            >
              <Box
                sx={{
                  "& a": {
                    textDecoration: "none",
                  },
                }}
              >
                <Link to={"/"}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: "700",
                      fontSize: "1.4rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Typography component={"img"} src={icon} width={"60px"} />
                    Crescent Intellects
                  </Typography>
                </Link>
              </Box>
              <Box pt={"2rem"}>
                <Button
                  component={Link}
                  to="/"
                  disableRipple
                  className="btn"
                >
                  Home
                </Button>
              </Box>
              <Box>
                {" "}
                <Button
                  component={Link}
                  to="/about"
                  disableRipple
                  className="btn"
                >
                  About Us
                </Button>
              </Box>
              <Box>
                <Button
                  component={Link}
                  to="/service"
                  disableRipple
                  className="btn"
                >
                  Services
                </Button>
              </Box>
              <Box> <Button component={Link} to="/career" disableRipple className="btn">Career</Button></Box>
              <Box>
                <Button
                  component={Link}
                  to="/contact"
                  disableRipple
                  className="btn"
                >
                  Contact Us
                </Button>
              </Box>
            </Box>
          </Drawer>
        </nav>
      </Container>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
