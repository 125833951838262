import React, { useEffect } from "react";
import ScrollButton from "../components/ScrollButton";
import Navbar from "../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../components/Footer";
import Title from "../components/Title";
import ServiceSectionBar from "./Services/ServiceSectionBar";
import deligence from "../Assets/Images/diligence.jpeg";

const Deligence = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <Grid container spacing={4} mb={"3rem"}>
          <Grid item md={8.5} sm={12} xs={12}>
            <Typography
              sx={{
                color: "#002856",
                fontSize: "1.1rem",
                fontWeight: "600",
                pb: "0.2rem",
              }}
            >
              IP Due Diligence
            </Typography>
            <Box
              sx={{
                "& p": {
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  pb: "1.5rem",
                  textAlign: { lg: "justify", xs: "inherit" },
                  color: "#000",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  fontSize: "1.1rem !important",
                  color: "#000 !important",
                }}
              >
                At Crescent Intellects, we understand the critical role that
                intellectual property (IP) plays in business transactions and
                strategic decision-making. Our IP Due Diligence services are
                designed to provide you with comprehensive assessments of
                intellectual property assets, helping you identify potential
                risks and opportunities associated with IP portfolios. With our
                expertise in IP law, technology, and business strategy, we offer
                tailored solutions to meet your specific due diligence needs and
                objectives.
              </Typography>
              <Typography
                component={"img"}
                width={"100%"}
                src={deligence}
                pb={"1rem"}
              />
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Comprehensive IP Portfolio Review:
                </b>
                <br />
                We conduct a thorough review of your IP portfolio to assess the
                scope, quality, and strategic alignment of your patents,
                trademarks, copyrights, and trade secrets. Our team examines the
                ownership, validity, and enforceability of each asset,
                identifying strengths, weaknesses, and potential areas of
                concern.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  IP Asset Identification and Valuation:
                </b>
                <br />
                We help you identify and assess the value of your IP assets,
                including patented inventions, registered trademarks,
                copyrighted works, and proprietary knowledge. Our experts
                perform detailed analyses of market trends, competitive
                landscapes, and industry benchmarks to determine the monetary
                worth and commercial potential of your IP portfolio.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Due Diligence for Mergers and Acquisitions:
                </b>
                <br />
                In merger and acquisition (M&A) transactions, conducting
                thorough due diligence on intellectual property assets is
                essential for mitigating risks and maximizing value. We assist
                buyers and sellers in assessing the IP aspects of M&A deals,
                including IP ownership, licensing agreements, litigation risks,
                and potential liabilities.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Due Diligence for Technology Transactions:
                </b>
                <br />
                In technology transactions such as licensing agreements, joint
                ventures, or technology transfers, understanding the IP rights
                and obligations involved is crucial for ensuring successful
                outcomes. We provide comprehensive due diligence services to
                evaluate the IP aspects of technology transactions, including
                patent ownership, licensing terms, and infringement risks.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  IP Due Diligence for Financing and Investment:
                </b>
                <br />
                Investors and lenders often require detailed assessments of
                intellectual property assets as part of financing or investment
                transactions. We offer IP due diligence services to help
                investors and lenders evaluate the IP aspects of potential
                investments, including IP ownership, valuation, and potential
                risks that may impact the investment decision.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  IP Due Diligence for IPOs and Public Offerings:
                </b>
                <br />
                Preparing for an initial public offering (IPO) or public
                offering requires thorough due diligence on intellectual
                property assets to ensure compliance with regulatory
                requirements and investor expectations. We assist companies in
                conducting IP due diligence in preparation for IPOs or public
                offerings, helping them identify and address potential
                IP-related issues that may arise during the offering process.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  IP Due Diligence for Regulatory Compliance:
                </b>
                <br />
                In industries subject to regulatory oversight, ensuring
                compliance with intellectual property laws and regulations is
                essential for avoiding legal risks and penalties. We provide IP
                due diligence services to help companies assess their IP
                compliance posture, identify potential areas of non-compliance,
                and develop strategies to address compliance issues proactively.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <ServiceSectionBar />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Deligence;
