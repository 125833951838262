import React, { useEffect } from "react";
import ScrollButton from "../components/ScrollButton";
import Navbar from "../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../components/Footer";
import Title from "../components/Title";
import ServiceSectionBar from "./Services/ServiceSectionBar";
import training from "../Assets/Images/training.png";

const TrainingAndWorkshop = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <Grid container spacing={4} mb={"3rem"}>
          <Grid item md={8.5} sm={12} xs={12}>
            <Typography
              sx={{
                color: "#002856",
                fontSize: "1.1rem",
                fontWeight: "600",
                pb: "0.2rem",
              }}
            >
              Customized IP Training and Workshops
            </Typography>
            <Box
              sx={{
                "& p": {
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  pb: "1.5rem",
                  textAlign: { lg: "justify", xs: "inherit" },
                  color: "#000",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  fontSize: "1.1rem !important",
                  color: "#000 !important",
                }}
              >
               Crescent Intellects offers tailored Customized IP Training and Workshops designed to empower individuals and organizations with the knowledge and skills needed to effectively manage and protect their intellectual property (IP) assets. With our extensive experience in IP law, strategy, and management, we provide comprehensive training programs and workshops that address your specific needs and objectives, whether you are a startup, a small business, or a multinational corporation.
              </Typography>
              <Typography
                component={"img"}
                width={"100%"}
                src={training}
                pb={"1rem"}
              />
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Needs Assessment and Curriculum Development:
                </b>
                <br />
                We begin by conducting a thorough needs assessment to understand your organization's unique challenges, goals, and objectives related to intellectual property. Based on our assessment, we develop a customized training curriculum that addresses your specific needs, covering topics such as IP fundamentals, patent and trademark protection, copyright law, trade secrets, licensing, enforcement, and compliance.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Customized Training Programs:
                </b>
                <br />
                We offer a wide range of customized training programs designed to meet the needs of individuals and organizations at all levels of expertise. Whether you are looking for introductory IP training for non-legal professionals, advanced training for IP professionals, or specialized training on specific IP topics, we tailor our programs to suit your audience's knowledge level, industry sector, and learning objectives.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Interactive Workshops and Seminars:
                </b>
                <br />
                Our interactive workshops and seminars provide hands-on learning experiences that engage participants and facilitate knowledge transfer. Led by experienced IP professionals, our workshops cover a variety of topics, including patent drafting and prosecution, trademark registration and enforcement, copyright clearance and licensing, trade secret protection, and IP strategy development. Participants have the opportunity to learn practical skills, ask questions, and participate in group discussions and case studies.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                Onsite and Virtual Training Delivery:
                </b>
                <br />
                We offer flexible training delivery options to accommodate your organization's preferences and logistical constraints. Whether you prefer onsite training at your location or virtual training via video conferencing platforms, we can accommodate your needs. Our virtual training sessions are interactive and engaging, utilizing multimedia presentations, breakout sessions, and collaborative tools to enhance the learning experience and promote active participation.
              </Typography>
                 
            </Box>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <ServiceSectionBar />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default TrainingAndWorkshop;
