import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import CareerSectionBar from "./CareerSectionBar";
import carrer from "../../Assets/Images/carrer.png"

const Career = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Career"} />
        </Box>
        <Box></Box>
        <Grid container spacing={4} mb={"3rem"}>
          <Grid item md={8.5} sm={12} xs={12}>
            <Typography
              sx={{
                color: "#002856",
                fontSize: "1.1rem",
                fontWeight: "600",
                pb: "0.2rem",
              }}
            >
              Why Choose Crescent Intellects?
            </Typography>

            <Box
              sx={{
                "& p": {
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  pb: "1.5rem",
                  textAlign: { lg: "justify", xs: "inherit" },
                  color: "#000",
                },
              }}
            >
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Innovation and Growth:
                </b>
                <br />
                At Crescent Intellects, we value innovation and continuous
                growth. We foster an environment that encourages creativity and
                initiative, providing opportunities for employees to contribute
                to the firm's success and their professional development.
              </Typography>
              <Typography
                component={"img"}
                width={"100%"}
                src={carrer}
                pb={"1rem"}
              />

              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Client-Centric Approach:
                </b>
                <br />
                Our client-centric approach drives everything we do. Working at
                Crescent Intellects means collaborating with a team dedicated to
                delivering exceptional service, strategic solutions, and value
                to our clients.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Learning and Development:
                </b>
                <br />
                We prioritize the ongoing learning and development of our team
                members. Through training programs, workshops, and exposure to
                diverse projects, we empower employees to expand their skills
                and stay ahead in the dynamic field of intellectual property.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Collaborative Culture:
                </b>
                <br />
                We believe in the power of collaboration. Crescent Intellects
                fosters a culture where teamwork, open communication, and
                knowledge-sharing thrive. Our collaborative environment ensures
                that every team member's contributions are valued.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Career Advancement:
                </b>
                <br />
                As a firm committed to individual and collective success, we
                provide clear paths for career advancement. Crescent Intellects
                recognizes and rewards the efforts of dedicated professionals,
                offering opportunities for career progression and leadership
                roles.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <CareerSectionBar />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Career;
