import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Desc from "../../components/Desc";
import patent from "../../Assets/Images/patent.jpeg";
import trademark from "../../Assets/Images/trademark.jpeg";
import copyright from "../../Assets/Images/copyright.jpeg";
import design from "../../Assets/Images/design.jpeg";
import trade from "../../Assets/Images/trade.jpeg";
import portfolio from "../../Assets/Images/portfolio.jpeg";
import diligence from "../../Assets/Images/diligence.jpeg";
import licensing from "../../Assets/Images/licensing.jpeg";
import litigation from "../../Assets/Images/litigation.jpeg";
import domain from "../../Assets/Images/domain.jpeg";
import training from "../../Assets/Images/training.png";
import { Link } from "react-router-dom";

const ServiceBox = () => {
  const ServiceBoxData = [
    {
      image: patent,
      title: <>Patent</>,
      desc: `Crescent Intellects assists clients in securing patent
      protection for their inventions. Our services include conducting
      patent searches, preparing and filing patent applications,
      responding to office actions, and managing the entire patent
      prosecution process.`,
      link: "/patent",
    },
    {
      image: trademark,
      title: <>Trademark</>,
      desc: " We specialize in helping clients protect their brand identities through trademark registration and management. Our services encompass trademark searches, filing and prosecution of trademark applications, maintenance of trademark portfolios, and handling trademark disputes.",
      link: "/trademark",
    },
    {
      image: copyright,
      title: <>Copyright</>,
      desc: `Crescent Intellects offers comprehensive copyright services,
      including the registration of original works, drafting and
      reviewing licensing agreements, and providing advice on
      copyright infringement issues. We work to safeguard the creative
      expressions of our clients.`,
      link: "/copyright",
    },
    {
      image: design,
      title: <>Design</>,
      desc: ` Design services at Crescent Intellects encompass comprehensive
      support, including searches, filings, renewals, and strategic
      portfolio management. Our expertise ensures the effective
      protection and management of clients' industrial designs,
      enhancing the overall value of their intellectual property
      assets.`,
      link: "/design",
    },
    {
      image: trade,
      title: <>Trade Secret Protection</>,
      desc: `Our firm assists clients in developing and implementing
      strategies to protect trade secrets and confidential
      information. This includes advising on best practices for
      internal protection, drafting non-disclosure agreements, and
      handling trade secret litigation when necessary.`,
      link: "/trade-secret",
    },
    {
      image: portfolio,
      title: <>IP Portfolio Management</>,
      desc: `Crescent Intellects provides strategic IP portfolio management
      services, helping clients optimize the value of their
      intellectual assets. We work with clients to identify and
      prioritize IP assets, aligning them with business goals, and
      offering ongoing management and maintenance.`,
      link: "/portfolio",
    },
    {
      image: diligence,
      title: <>IP Due Diligence</>,
      desc:` We conduct thorough IP due diligence for mergers, acquisitions,
      and other business transactions. Our services include assessing
      the IP portfolios of target companies, identifying risks and
      opportunities, and providing strategic advice to support
      informed decision-making.`,
      link: "/diligence",
    },
    {
      image: licensing,
      title: <>Licensing and Technology Transfer</>,
      desc: `Crescent Intellects facilitates licensing agreements and
      technology transfers, negotiating terms that maximize the value
      of our clients' intellectual property. We ensure that licensing
      agreements are comprehensive, enforceable, and aligned with our
      clients' business objectives.`,
      link: "/licensing",
    },
    {
      image: litigation,
      title: <>IP Litigation and Enforcement</>,
      desc: ` In cases of infringement or disputes, Crescent Intellects offers
      IP litigation services. Our experienced legal team represents
      clients in court, arbitration, or alternative dispute resolution
      forums, working to protect and enforce their intellectual
      property rights.`,
      link: "/litigation",
    },
    {
      image: domain,
      title: <>Domain Name Disputes</>,
      desc: ` We assist clients in resolving domain name disputes, including
      cases of cybersquatting and trademark infringement in the online
      space. Our services include dispute resolution proceedings,
      negotiation, and legal action when necessary.`,
      link: "/domain",
    },
    {
      image: training,
      title: <>Customized IP Training and Workshops</>,
      desc: `Crescent Intellects provides customized training and workshops
      on intellectual property topics. These sessions are designed to
      educate clients and their teams on the importance of IP, best
      practices for protection, and strategies for maintaining a
      strong IP portfolio.`,
      link: "/training",
    },
  ];
  // const isSecondBoxInRow = index % 3 === 1;
  return (
    <Container data-aos="fade-up">
      <Grid container spacing={5} mb={"4rem"} >
      {ServiceBoxData.map((e, index) => {

  return (
    <Grid item md={4} sm={6} xs={12} key={index}>
      <Box textAlign={"center"} p={"5px"}>
        <Box
          sx={{
            // height: "200px",
            // width: "100%",
            // background: isSecondBoxInRow ? "#fff" : "rgba(34, 75, 141, 0.8)", // Change background color for the second box in each row
            // borderRadius: "5px",
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            // mb: "0.5rem",
            // boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
          }}
          position={"relative"}
          lineHeight={0}
          mb={"0.5rem"}
        >
         <Typography component={"img"} src={e.image} width={"100%"}/>
         <Box
            sx={{
              width: "100%",
              p: "10px 16px",
              // borderRadius: "5px",
              position: "absolute",
              bottom: "0",
              background: "rgba(34, 34, 34, .5)",
            }}
          >
           <Typography
            sx={{
              fontSize: { md: "1.2rem", xs: "1.2rem" },
              fontWeight: "bold",
              fontStyle:"italic",
              // fontFamily:"KanitBold !important",
              // p: "1rem",
              color: "#fff"
            }}
          >
            {e.title}
          </Typography>
          </Box>
        </Box>
       
    
        <Typography sx={{ "& p": { fontSize: "14px !important" } }}>
          <Desc text={e.desc} />
        </Typography>
        <Button
          disableRipple
          sx={{
            mt: "0.7rem",
            padding: "5px 15px !important",
            color: "#fff !important",
            fontWeight: "600 !important",
            fontSize: "12px !important",
            fontFamily: "KanitRegular !important",
            transition: "0.2s all !important",
            backgroundColor: "rgb(34, 75, 141)",
            textTransform: "capitalize !important",
            borderRadius: "2",
            border: "2px solid transparent",
            "&:hover": {
              transition: "0.2s all !important",
              border: "2px solid rgb(34, 75, 141)",
              backgroundColor: "transparent",
              color: "rgb(34, 75, 141) !important",
            },
          }}
          component={Link}
          to={e.link}
        >
          More Info
        </Button>
      </Box>
    </Grid>
  );
})}

      </Grid>
    </Container>
  );
};

export default ServiceBox;
