import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import CareerSectionBar from "./CareerSectionBar";
import carrer from "../../Assets/Images/carrer.png"



const HowToApply = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);

      }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Career"} />
        </Box>
        <Box>
        </Box>
       <Grid container spacing={4} mb={"3rem"}>
        <Grid item md={8.5} sm={12} xs={12}>
        <Typography sx={{color:"#002856",fontSize:"1.1rem",fontWeight:"600",pb: "0.2rem",}}>How to Apply</Typography>
  
        <Box
          sx={{
            "& p": {
              fontSize: "1.1rem",
              fontWeight: "500",
              pb: "1.5rem",
              textAlign: { lg: "start", xs: "inherit" },
              color:"#000"
              
            },
          }}
        >
          <Typography>
          If you are passionate about intellectual property, possess relevant skills, and are ready to contribute to a dynamic team, we invite you to explore our current job openings. Send your resume and cover letter to <a href="mailto:career@crescentintellects.com">career@crescentintellects.com</a>, indicating your area of interest and expertise.
          </Typography>
          <Typography component={"img"} width={"100%"} src={carrer} pb={"1rem"}/>
          <Typography>
          Embark on a rewarding career with Crescent Intellects, where your talents and aspirations align with a firm dedicated to shaping the intellectual property landscape.
          </Typography>
        </Box>
        </Grid>
        <Grid item md={3.5} sm={12} xs={12}>
        <CareerSectionBar/>
        </Grid>
       </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default HowToApply;
