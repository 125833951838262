import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import AboutSectionBar from "../AboutPage/AboutSectionBar";
import environment from "../../Assets/Images/environment.png"

const WorkEnvironment = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"About Us"} />
        </Box>
        <Grid container spacing={4} mb={"3rem"}>
          <Grid item md={9} sm={12} xs={12}>
            <Typography
              sx={{
                color: "#002856",
                fontSize: "1.1rem",
                fontWeight: "600",
                pb: "0.2rem",
              }}
            >
              Work Environment
            </Typography>
            <Box
              sx={{
                "& p": {
                  fontSize: "1.15rem",
                  fontWeight: "500",
                  pb: "1.5rem",
                  textAlign: { lg: "justify", xs: "inherit" },
                  color: "#000",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  fontSize: "1.1rem !important",
                  color: "#000 !important",
                }}
              >
                Crescent Intellects takes pride in cultivating a work
                environment that is not only professional and innovative but
                also reflects a set of core values that guide our team's actions
                and interactions. Our commitment to these values shapes the
                culture within our firm and influences the way we approach our
                work and relationships.
              </Typography>
              <Typography
                component={"img"}
                width={"100%"}
                src={environment}
                // src="https://www.amsshardul.com/wp-content/uploads/2020/04/values-2-f-768x321.png"
                pb={"1.5rem"}
              />
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Collaboration and Teamwork:
                </b>
                <br />
                At Crescent Intellects, we believe in the power of
                collaboration. Our work environment is characterized by open
                communication, shared ideas, and a collaborative spirit. We
                recognize that the best solutions often emerge through the
                collective efforts of a diverse and talented team.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Innovation and Creativity:
                </b>
                As advocates for innovation, Crescent Intellects encourages a
                culture of creativity. We recognize and celebrate the unique
                perspectives and creative contributions of each team member. Our
                work environment fosters an atmosphere where new ideas are not
                only welcomed but actively sought out and valued.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Professional Growth and Development:
                </b>
                <br />
                We are committed to the continuous growth and development of our
                team members. Crescent Intellects provides opportunities for
                learning, training, and skill enhancement, ensuring that our
                professionals stay at the forefront of the ever-evolving field
                of intellectual property.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Ethical Conduct and Integrity:
                </b>
                <br />
                Ethical conduct is non-negotiable at Crescent Intellects. We
                uphold the highest standards of integrity in all our
                interactions, both within the team and with clients. Our
                commitment to ethical practices is foundational to building
                trust and credibility in the work we do.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Client-Centric Focus:
                </b>
                <br />
                Our work environment is centered around a client-centric
                approach. We understand that our success is intricately tied to
                the success of our clients. Every team member is dedicated to
                providing exceptional service, understanding client needs, and
                delivering solutions that align with their goals.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Adaptability and Resilience:
                </b>
                <br />
                The intellectual property landscape is dynamic, and Crescent
                Intellects thrives on adaptability and resilience. Our work
                environment encourages flexibility and the ability to adapt to
                changing circumstances. We view challenges as opportunities for
                growth and innovation.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Diversity and Inclusion:
                </b>
                <br />
                Crescent Intellects values diversity and inclusion as essential
                components of a vibrant and successful workplace. We actively
                seek and embrace a diverse range of perspectives, backgrounds,
                and experiences, recognizing that diversity enhances our ability
                to solve complex problems.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Work-Life Balance:
                </b>
                <br />
                We understand the importance of maintaining a healthy work-life
                balance. Crescent Intellects is committed to fostering an
                environment where team members can excel in their professional
                responsibilities while also enjoying a fulfilling personal life.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Environmental and Social Responsibility:
                </b>
                <br />
                Crescent Intellects recognizes its broader responsibility to
                society and the environment. We are committed to sustainable
                practices and social responsibility, seeking ways to positively
                impact the communities in which we operate.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Excellence and Quality:
                </b>
                <br />
                Striving for excellence is ingrained in our culture. Crescent
                Intellects is dedicated to delivering high-quality services that
                meet or exceed the expectations of our clients. We set high
                standards for ourselves and consistently work towards achieving
                them.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3} sm={12} xs={12}>
            <AboutSectionBar />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default WorkEnvironment;
