import React, { useEffect } from "react";
import ScrollButton from "../../components/ScrollButton";
import Navbar from "../../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Title from "../../components/Title";
import AboutSectionBar from "./AboutSectionBar";
import who from "../../Assets/Images/who.png"



const AboutPage = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"2.5rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"About Us"} />
        </Box>
        <Box>
        </Box>
       <Grid container spacing={4} mb={"3rem"}>
        <Grid item md={9} sm={12} xs={12}>
        <Typography sx={{color:"#002856",fontSize:"1.1rem",fontWeight:"600",pb: "0.2rem",}}>Who we are</Typography>
  
        <Box
          sx={{
            "& p": {
              fontSize: "1.1rem",
              fontWeight: "500",
              pb: "1.5rem",
              textAlign: { lg: "justify", xs: "inherit" },
              color:"#000"
              
            },
          }}
        >
          <Typography>
            Welcome to Crescent Intellects, a professional intellectual
            property (IP) firm committed to navigating the ever-evolving
            landscape of innovation and safeguarding the intellectual assets of
            our clients. Established with a vision to redefine the way
            intellectual property is managed and protected, Crescent Intellects
            stands at the forefront of providing comprehensive solutions
            tailored to the unique needs of businesses and individuals alike.
          </Typography>
          <Typography component={"img"} width={"100%"} src={who} pb={"1rem"}/>
          <Typography>
            At Crescent Intellects, our vision is rooted in fostering a culture
            of innovation and creativity, where intellectual property is not
            just safeguarded but serves as a catalyst for growth and
            development. We believe in empowering our clients to capitalize on
            their ideas, inventions, and creations by offering strategic
            guidance and unparalleled expertise in navigating the complexities
            of intellectual property law.
          </Typography>
          <Typography>
            In a world driven by constant technological advancements and
            creative breakthroughs, Crescent Intellects envisions a future where
            businesses and individuals can thrive in an environment that
            encourages and rewards innovation. Our commitment extends beyond
            traditional legal services; we strive to be partners in our clients'
            success stories, providing them with the tools and strategies needed
            to leverage their intellectual capital for sustained competitive
            advantage.
          </Typography>
          <Typography>
            At Crescent Intellects, we understand that intellectual property is
            not merely a legal concept but a cornerstone of progress. We are
            dedicated to ensuring that our clients' ideas are not only protected
            but also positioned strategically to make a lasting impact on their
            respective industries. Join us on a journey where innovation meets
            protection, and together, we'll shape a future where intellectual
            endeavors are the driving force behind positive change and
            advancement.
          </Typography>
        </Box>
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
        <AboutSectionBar/>
        </Grid>
       </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default AboutPage;
