import React, { useEffect } from "react";
import ScrollButton from "../components/ScrollButton";
import Navbar from "../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../components/Footer";
import Title from "../components/Title";
import ServiceSectionBar from "./Services/ServiceSectionBar";
import Tradesecret from "../Assets/Images/Tradesecret.png";

const TradeSecret = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <Grid container spacing={4} mb={"3rem"}>
          <Grid item md={8.5} sm={12} xs={12}>
            <Typography
              sx={{
                color: "#002856",
                fontSize: "1.1rem",
                fontWeight: "600",
                pb: "0.2rem",
              }}
            >
              Trade Secret Protection
            </Typography>
            <Box
              sx={{
                "& p": {
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  pb: "1.5rem",
                  textAlign: { lg: "justify", xs: "inherit" },
                  color: "#000",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  fontSize: "1.1rem !important",
                  color: "#000 !important",
                }}
              >
                At Crescent Intellects, we understand that your trade secrets
                are among your most valuable assets. Our comprehensive Trade
                Secret Protection services are designed to safeguard your
                proprietary information, ensuring that it remains confidential
                and secure. With our expertise in intellectual property law and
                trade secret management, we provide tailored solutions to meet
                your specific needs and protect your competitive advantage.
              </Typography>
              <Typography
                component={"img"}
                width={"100%"}
                src={Tradesecret}
                pb={"1rem"}
              />
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Strategic Assessment:
                </b>
                <br />
                We conduct a thorough assessment of your intellectual property
                portfolio to identify and categorize your trade secrets. This
                includes innovative processes, formulas, customer lists,
                marketing strategies, and any other confidential information
                critical to your business success. Our experts work closely with
                your team to evaluate potential vulnerabilities and develop a
                customized protection strategy aligned with your business goals.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Trade Secret Identification and Documentation:
                </b>
                <br />
                We help you identify and document your trade secrets, ensuring
                that no valuable intellectual asset goes unnoticed. Our
                systematic approach includes cataloguing all relevant
                information, assessing its significance to your business
                operations, and implementing measures to protect its
                confidentiality. By meticulously documenting your trade secrets,
                we lay the groundwork for robust protection and enforcement.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Implementation of Protective Measures:
                </b>
                <br />
                We deploy a multi-layered defence strategy to safeguard your
                trade secrets from unauthorized access or disclosure. This
                includes implementing physical security protocols, such as
                restricted access to sensitive areas or documents, as well as
                technological safeguards like encryption and access controls.
                Additionally, we assist in drafting and implementing
                non-disclosure agreements (NDAs) with employees, contractors,
                and partners to further fortify confidentiality.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Ongoing Monitoring and Enforcement:
                </b>
                <br />
                Our vigilant monitoring services enable early detection of
                potential threats or breaches to your trade secrets. We utilize
                advanced monitoring tools and surveillance techniques to
                proactively identify unauthorized access or disclosure, allowing
                for prompt remedial action and enforcement, as necessary.
                Whether it is internal audits, employee training, or legal
                recourse against infringers, we ensure that your intellectual
                assets are protected at all times.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Compliance and Risk Management:
                </b>
                <br />
                Navigating the complex landscape of intellectual property laws
                and regulations can be challenging. We provide expert guidance
                on compliance with relevant laws and industry standards,
                ensuring that your trade secret protection efforts remain in
                full compliance while minimizing legal risks and liabilities.
                Our proactive risk management approach helps mitigate potential
                threats and safeguard your intellectual assets against
                litigation or regulatory penalties.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <ServiceSectionBar />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default TradeSecret;
