import React, { useEffect } from "react";
import ScrollButton from "../components/ScrollButton";
import Navbar from "../components/header/Navbar";
import { Box, Container, Grid, Typography } from "@mui/material";
import Footer from "../components/Footer";
import Title from "../components/Title";
import ServiceSectionBar from "./Services/ServiceSectionBar";
import litigation from "../Assets/Images/litigation.jpeg";

const Litigations = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        "& p,li": {
          fontFamily: "KanitRegular!important",
        },
      }}
    >
      <ScrollButton />
      <Navbar />
      <Container data-aos="fade-up">
        <Box py={"3rem"} textAlign={"center"}>
          <Title textTransform={"capitalize"} title={"Our Services"} />
        </Box>
        <Grid container spacing={4} mb={"3rem"}>
          <Grid item md={8.5} sm={12} xs={12}>
            <Typography
              sx={{
                color: "#002856",
                fontSize: "1.1rem",
                fontWeight: "600",
                pb: "0.2rem",
              }}
            >
              IP Litigation and Enforcement Services
            </Typography>
            <Box
              sx={{
                "& p": {
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  pb: "1.5rem",
                  textAlign: { lg: "justify", xs: "inherit" },
                  color: "#000",
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500 !important",
                  fontSize: "1.1rem !important",
                  color: "#000 !important",
                }}
              >
                Crescent Intellects specializes in providing expert support and
                guidance for intellectual property (IP) litigation and
                enforcement matters. Our comprehensive IP Litigation and
                Enforcement services are designed to protect your IP rights,
                defend against infringement, and resolve disputes effectively.
                With our deep expertise in IP law, litigation strategy, and
                dispute resolution, we deliver tailored solutions to meet your
                specific needs and objectives.
              </Typography>
              <Typography
                component={"img"}
                width={"100%"}
                src={litigation}
                pb={"1rem"}
              />
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Case Assessment and Strategy Development:
                </b>
                <br />
                We begin by conducting a thorough assessment of your IP
                litigation or enforcement matter. Our team evaluates the
                strength of your IP rights, the merits of your case, and the
                potential risks and opportunities involved. Based on our
                analysis, we develop a strategic litigation or enforcement
                strategy tailored to your goals and objectives.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Pre-Litigation Counselling and Negotiation:
                </b>
                <br />
                In many cases, resolving IP disputes through negotiation or
                alternative dispute resolution methods can be more efficient and
                cost-effective than litigation. We provide pre-litigation
                counselling and negotiation services to help you resolve
                disputes amicably, protect your interests, and avoid protracted
                legal battles whenever possible.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  IP Litigation Representation:
                </b>
                <br />
                If litigation becomes necessary, our experienced litigators
                provide skilled representation in state and federal courts, as
                well as before administrative bodies such as the United States
                Patent and Trademark Office (USPTO) and the International Trade
                Commission (ITC). We handle all aspects of IP litigation, from
                pleadings and discovery to motion practice, trial, and appeal.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Enforcement of IP Rights:
                </b>
                <br />
                We assist you in enforcing your IP rights against infringers,
                counterfeiters, and other unauthorized users. Our team utilizes
                a variety of enforcement measures, including cease-and-desist
                letters, takedown notices, customs enforcement actions, and
                civil litigation, to stop infringement and protect your IP
                assets effectively.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Defence Against IP Claims:
                </b>
                <br />
                If you are facing allegations of IP infringement or
                misappropriation, we provide vigorous defence representation to
                protect your rights and interests. Our experienced attorneys
                develop robust defence strategies tailored to your specific
                circumstances, challenging the validity and scope of the
                opposing party's IP rights, and mounting a strong defence
                against infringement claims.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Expert Witness Testimony:
                </b>
                <br />
                Our team includes seasoned experts who can provide expert
                witness testimony on a wide range of IP-related issues,
                including patent validity, infringement, damages, and trade
                secret misappropriation. Whether you need expert testimony in
                court or arbitration proceedings, we offer reliable, credible,
                and persuasive expert testimony to support your case.
              </Typography>
              <Typography>
                <b style={{ fontSize: "1.1rem", color: "#002856" }}>
                  Settlement Negotiation and Resolution:
                </b>
                <br />
                We explore all available options for resolving IP disputes
                efficiently and favourably, including settlement negotiations,
                mediation, and arbitration. Our experienced negotiators work
                tirelessly to achieve favourable outcomes that protect your
                interests while minimizing costs and disruption to your business
                operations.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3.5} sm={12} xs={12}>
            <ServiceSectionBar />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Litigations;
